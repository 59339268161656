<template>
  <div class="dsf_nc_base_train">
    <div class="page_title">
      <img class="page_title_icon" src="/static/img/product/jbpx.png">基本培训
    </div>
    <div class="page_bg" @click="click(classObj)">
      <dsf-image class="page_bg_img" :src="classObj['cover'] | imgFormat"  />
      <div class="page_bg_content">
        <div class="class_title">{{classObj.name}}</div>
        <div class="class_bot">
          <span>开班总数：{{classObj.total || 0}}</span>
          <span>进行中的班级数：{{classObj.going || 0}}</span>
        </div>
      </div>
    </div>
    <div class="base_train_card">
      <div class="base_train_card_item" v-for="(item, index) in classData" :key="item._id">
        <DsfNcBaseTrainCard :objData="item" :clickConfig="clickConfig" @click="click" />
      </div>
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfNcBaseTrain',
  ctrlCaption: '贵州-基本培训',
  mixins: [$mixins.control],
  props: {
    clickConfig: {
      type: Object,
      default: null,
    },
 
  },
  components: {},
  data() {
    return {
      classObj: {},
      classData: [],
    };
  },
  watch: {},
  created() {
   if(!this.isDesign){
    this.getClassData();
   }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    click(it) {
      it = it.args ? it.args : it;
      if (this.$listeners.click) {
        this.$dispatch("click", it);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, it);
        this.$open({ url, state });
      }
    },
    getClassData() {
      let that = this;
      that.dsf.http
        .get('nc/basetrain/baseTrainList', {})
        .done(function (res) {
          if (res.success) {
            that.classObj = res.data[0];
            that.classData = res.data.slice(1);
          }
        })
        .error(function (error) {})
        .always(function () {});
    }
  }
});
</script>
<style></style>
