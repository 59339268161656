<template>
  <div class="dsf_nc_classcolum">
<!--     <div :style="{ height: '0px' }">
      <course-plays :nobase="true" ref="theplay" :videoSrc="setVideo(cousewareInfo)"></course-plays>
    </div> -->
    <!-- 右上角勋章 -->
    <div :class="['zl_xz', {zl_xz_hui: !isMedal}]" v-if="setting_nc_other_medal">
      <el-tooltip effect="dark" :content="medalTips" placement="top-start">
        <img class="zl_xz_img" src="~@/assets/styles/img/zl_xz.png" alt="">
      </el-tooltip>
    </div>
    <div class="video-box" ref="bghieght">
      <div class="vlogo">
        <img :src="setImg(columninfo.logo)" alt="" />
      </div>
      <div class="v-bg">
        <img :style="{ minHeight: bgimghieght }" :src="setImg(columninfo.pcCover)" alt="" />
      </div>
      <div class="v-box">
        <!-- Swiper -->
        <div class="swiper-box">
          <!-- <div class="swiper-container" id="swiperContainer"> -->
          <swiper ref="mySwiper" v-bind:options="swiperOption" id="swiperContainer">
            <!-- <div class="swiper-wrapper" id="swiperWrapper"> -->
            <swiper-slide v-for="(item, index) in unitList" :key="index">
              <div :class="['title', 'ell2', topactive == index ? 'active' : '']" @click="changeactive(index, 1)">
                {{ item.unitName }}
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
        <div class="rightcontent">
          <div
            :style="{ left: iconisright }"
            :class="['left-menu-btn', isright ? 'rightbtn' : '']"
            id="showMenuBtn"
            status="right"
            @click="(isright = !isright), isright ? ((left = '-192px'), (iconisright = '0px')) : ((left = '0px'), (iconisright = '192px'))"
          ></div>
          <div class="leftmenu" :style="{ left: left }">
            <ul id="kcList" class="kcList_J_course_content">
              <li v-for="(item, index) in classList" :key="index" :class="leftactive == index ? 'act' : ''" @click="changeactive(index, 0)">
                <el-tooltip class="item" effect="light" popper-class="class_coulumn_popper" :content="item.name" placement="right-start">
                  <span>第{{ numberToUpperCase(index) }}课</span>
                </el-tooltip>
              </li>
            </ul>
          </div>
          <div class="videoplay" @mouseenter="rightslid = true" @mouseleave="rightslid = false">
            <p class="videoPlayName">
              {{ classList[leftactive]?.name }}
            </p>
            <!-- <div class="playbox">播放器</div> -->
            <!-- 播放器相关开始 -->
            <div class="player-wrap" oncontextmenu="return false">
              <div class="player-inner" id="PlayerInner">
                <div id="video" :class="['playerWrap', 'video-player', video_minnwidth ? 'video-minwidth' : '']">
                  <gzplayer
                    v-if="showPlayer"
                    ref="gzplayer"
                    :courseId="courseId"
                    :cover="baseInfo && baseInfo.cover"
                    :is_allow_drag="true"
                    :videoStatus="videoStatus"
                    :videoSource="setVideo"
                    :id="childreninfo && childreninfo[coursewareIndex] && childreninfo[coursewareIndex]['id']"
                    :info="curItemInfo"
                    :childreninfo="childreninfo"
                    @playerPlayingTime="playerPlayingTime"
                    @playerScreenshot="playerScreenshot"
                    @updateProgress="updateProgress"
                    @playHasEnd="playEnd"
                  ></gzplayer>
                </div>
                <!-- <video controlslist="nodownload" :class="['playerWrap', 'video-player', video_minnwidth ? 'video-minwidth' : '']" :src="setVideo(cousewareInfo)" controls autoplay></video> -->
                <div class="audio-wrap" id="AudioWrap">
                  <div class="audio-center-icon audioPauseIcon" id="AudioIconHandle"></div>
                  <!-- <audio
                    controls
                    autoplay
                    src="/test/audio.ogg"
                    id="CourseAudio"
                    controlsList="nodownload"
                  >
                    <p>
                      Your browser does not support the
                      <code>audio</code> element.
                    </p>
                  </audio> -->
                </div>
                <!-- 右侧悬浮 -->
                <div class="video-player">
                  <div
                    :class="['chapter', rightslid ? 'righthover' : '']"
                    @mouseenter="rightslid = true"
                    @mouseleave="rightslid = true"
                    :style="{
                      marginRight: `${1 ? '0' : '300'}`,
                      right: `${video_minnwidth ? 352 : 50}px`,
                    }"
                  >
                    <ul class="chapter-handle" :style="{height: setting_nc_other_draft ? '240px' : '160px'}">
                      ;
                      <li @click="unitclick" :class="suspension == 1 ? 'suspension' : ''">
                        <i class="c-icon-zj"></i>
                        <span>章节</span>
                      </li>
                      <li v-if="setting_nc_other_draft" @click="manuscriptsclick" :class="suspension == 2 ? 'suspension' : ''">
                        <i class="c-icon-wg"></i>
                        <span>文稿</span>
                      </li>
                      <li v-if="isLogin" @click="noteshow" :class="suspension == 3 ? 'suspension' : ''">
                        <i class="c-icon-bj"></i>
                        <span>笔记</span>
                      </li>
                      <!-- <li @click="noteshow" :class="suspension == 3 ? 'suspension' : ''">
                        <i class="c-icon-bj"></i>
                        <span>下载课件</span>
                      </li> -->
                    </ul>
                  </div>
                  <!-- 右侧隐藏 -->
                  <div class="chapter-content" id="J_chapterContent" @mouseenter="rightslid = true" :style="{ left: `${rightIshide ? '790' : '440'}px` }">
                    <i class="icon iconfont icon-right d-p-abs Fold" :class="[suspension == 2 ? 'gray' : '']" id="Fold" @click="settimeout"></i>
                    <h3 class="chapter-title">
                      <i class="icon-zj"></i>
                      章节
                    </h3>
                    <!-- 章节展示 -->
                    <div id="chapterWarp">
                      <h3 class="wrap-tit" style="display: none"></h3>
                      <div class="chapter-con-item">
                        <h3 class="chapter-item-title titAllow">
                          <span class="chapter-item-title-ipt unIpt">{{ classList[leftactive]?.categoryText }}</span>
                          <span class="chapter-item-title-name">{{ classList[leftactive]?.name }}</span>
                          <span class="chapter-item-time">
                            {{ classList[leftactive]?.period }}学时
                            <i class="icon-zk toggleSj"></i>
                          </span>
                        </h3>
                        <div class="chapter-content-list-wrap" style="display: block">
                          <ul class="chapter-content-list J_course_content" data-count="1">
                            <li :class="['kcAllow', coursewareIndex == index ? 'selConListItem' : '']" v-for="(item, index) in childreninfo" :key="index" @click="changecoureswareIndex(index)">
                              <el-progress type="circle" :percentage="item.finishedRate" :width="40" color="#ff6700" :stroke-width="2"></el-progress>
                              <span>
                                <!-- <i class="course-icon c-icon-sp"></i> -->
                                {{ item.title }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- 文稿展示 -->
                    <div
                      class="files-wrap"
                      :style="{
                        display: `${manuscriptsHide ? 'none' : 'block'}`,
                      }"
                    >
                      <!-- <i class="icon iconfont icon-right d-p-abs Fold" id="Fold" @click="settimeout"></i> -->
                      <label class="files-title">
                        <i class="icon-zj"></i>
                        文稿
                      </label>
                      <iframe :src="setIfarem()" frameborder="0" id="Files" class="files"></iframe>
                    </div>
                  </div>
                </div>
                <!-- 笔记展示 -->
                <div class="course-note-wrap" id="CourseNote" :style="{ top: `${noteHide ? '-640px' : '0px'}` }">
                  <i class="icon iconfont icon-right note-fold Fold" id="NoteFold" @click="settimeout(1)"></i>
                  <div class="course-note">
                    <div class="course-note-input-warp">
                      <textarea
                        cols="30"
                        v-model="noteContent"
                        rows="10"
                        @focus="handleScreenshot()"
                        placeholder="请输入笔记内容"
                        class="course-note-pl course-note-text"
                        id="NodeTextArea"
                        maxlength="1000"
                      ></textarea>
                      <div class="course-note-handle">
                        <label class="course-note-count">
                          <span id="NoteCounts">{{ noteContent.split("").length }}</span>
                          /1000
                        </label>
                        <div class="layui-form">
                          <div class="layui-form-item">
                            <span class="layui-form-label">公开</span>
                            <el-switch width:20px v-model="value" active-color="#13ce66" inactive-color="#cb011b" active-text="开" inactive-text="关"></el-switch>
                          </div>
                        </div>
                        <button class="course-note-keep" id="J_keep_note" @click="save">保存</button>
                      </div>
                    </div>
                    <div class="course-note-content">
                      <div class="course-note-con-class" id="J_showNoteType">
                        <div class="course-note-con-class-item selClassItem" data-type="0">
                          <el-radio v-model="radio" label="我的" @change="getNotList(1)">我的</el-radio>
                        </div>
                        <div class="course-note-con-class-item" data-type="1">
                          <el-radio v-model="radio" label="全部" @change="getNotList(2)">全部</el-radio>
                        </div>
                      </div>

                      <div class="course-comment-desc dsf_nc_note_item" id="J_noteList">
                        <!-- <dsf-nc-note-item
                          :curItem="classList[leftactive]"
                          :relationId="classList && classList[leftactive] && classList[leftactive]['businessId']"
                          queryiType="course"
                          :item="baseInfo"
                        /> -->
                        <div class="dsf_nc_note_item_inner">
                          <div class="note-list-wrap">
                            <div class="note-list">
                              <template v-if="noteList.length > 0">
                                <div class="note-list-item" v-for="(item, index) in noteList" :key="item._id">
                                  <p class="note-list-item-top">
                                    <span>{{ getPrefixText(item) }}: {{ item["nc_learn_note_resourcelistsource.node_time"] || "" }}</span>
                                    <span>{{ item["nc_learn_note_resourcelistsource.r_time"] || "" }}</span>
                                  </p>
                                  <div class="note-list-item-desc">
                                    <div class="note-img">
                                      <dsf-image :src="item['nc_learn_note_resourcelistsource.node_picture'] | imgFormat" :errorSrc="dsf.config.setting_nc_image_default_img" fit="cover" />
                                    </div>
                                    <div class="note-info">
                                      <div>
                                        <span :style="{ color: fontColor }">
                                          <dsf-icon name="person" />
                                          {{ item["nc_learn_note_resourcelistsource.user_name"] }}
                                        </span>
                                        <div>
                                          <span @click="updateItem(index)" v-if="item['nc_learn_note_resourcelistsource.is_own'] == 1">编辑</span>
                                          <span @click="delNote(item)" v-if="item['nc_learn_note_resourcelistsource.is_own'] == 1">删除</span>
                                        </div>
                                      </div>
                                      <div class="node-text" v-if="item.readOnly" :style="{ color: fontColor }">
                                        {{ item["nc_learn_note_resourcelistsource.node_words"] }}
                                      </div>
                                      <el-input
                                        type="textarea"
                                        ref="noteText"
                                        v-else
                                        class="node-text node-text-area"
                                        v-model="tempText"
                                        @focus="handleScreenshot()"
                                        @blur="saveNote(index, item)"
                                      ></el-input>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <div class="no-data no-data-note" v-else>
                                <i class="no-data-icon"></i>
                                <p>暂无笔记</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 播放器相关结束 -->
            <!-- <div class="warnInfo"> -->
            <div :class="['warnInfo', warnInfo ? 'warnInfoafter' : '']">暂时还没有相应的文稿,先看看视频吧。</div>
          </div>
        </div>
        <div class="videomess">
          <p>课程简介</p>
          <div class="messtext" v-html="columninfo.describe"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CoursePlays from "../course/coursePlayer";
import gzplayer from "../course/gzplayer";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default dsf.component({
  name: "DsfNcClassColumn",
  ctrlCaption: "贵州首页课程专栏",
  mixins: [$mixins.control],
  props: {},
  data() {
    return {
      showPlayer: false,
      curTime: "",
      noteContent: "",
      tempText: "",
      unitList: [],
      form: {
        desc: "",
        public: false,
        showType: "我的",
      },
      classList: [],
      curItem: null,
      unitindex: 1,
      isright: false,
      videoStatus: false,
      sfgkValue: "",
      left: "0px",
      iconisright: "192px",
      // 课程激活
      leftactive: 0,
      // 单元激活
      topactive: 0,
      // 章节文稿笔记激活
      suspension: 0,
      // 视频资源
      cousewareInfo: [],
      // 专栏信息
      columninfo: {},
      // 课件激活
      coursewareIndex: 0,
      // 课件视频index
      videoIndex: 0,
      bgimghieght: null,
      video_minnwidth: false,
      rightslid: false,
      // 默认章节隐藏
      rightIshide: false,
      // 默认笔记隐藏
      noteHide: true,
      // 默认文稿隐藏
      manuscriptsHide: true,
      // 课程课件信息
      childreninfo: [],
      warnInfo: false,
      unitisChange: false,
      classChange: false,
      swiperOption: {
        // loop: true,
        // direction: "vertical",
        pagination: {
          type: "fraction",
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 4,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      value: true,
      radio: "我的",
      noteList: [],
      baseInfo: {},
      curItemInfo: null,
      isMedal: false,
    };
  },
  created() {
    this.getUnitData();
    this.getClassInfo();
    if(this.setting_nc_other_medal){
      this.getClassMedal();
    }
    // 获取背景高度
    this.$nextTick(() => {
      this.bgimghieght = this.$refs.bghieght?.clientHeight + "px";
    });
  },
  watch: {
    topactive: {
      handler(newValue, oldval) {
        if (newValue != oldval) {
          this.unitisChange = true;
        }
      },
    },
    leftactive: {
      handler(newValue, oldval) {
        if (newValue) {
          this.classChange = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getSeektime", "getCurPlayTime", "getScreenshot"]),
    setting_nc_other_draft(){
      return dsf.config.setting_nc_other_draft == '1';
    },
    setting_nc_other_medal(){
      return dsf.config.setting_nc_other_medal == '1';
    },
    medalTips(){
      return this.isMedal ? "已获得勋章" : "待完成所有课程学习，可获得勋章~";
    },
    // 课件id
    coursewareId() {
      // if (this.curItem?.businessId) return this.curItem.subId;
      let id = "";
      if (this.cousewareInfo.locationSite) {
        id = this.cousewareInfo.locationSite.id;
      }
      return id;
    },
    courseId() {
      if (this.curItem?.businessId) return this.curItem.businessId;
      return "";
    },
    coverImage() {
      let cover = "";
      if (this.liveCover) cover = this.liveCover;
      if (this.getScreenshot) {
        cover = JSON.stringify(this.getScreenshot);
      } else {
        if (this.item?.cover) cover = this.item.cover;
      }
      return cover;
    },
    //当前课件学习进度
    percentage() {},
    isLogin(){
      return dsf.getCookie("user_id") ? true : false;
    },
    setVideo(){
      // console.log(this.cousewareInfo, "视频视频视频视频");
      let src = "";
      // console.log(
      //   this.cousewareInfo,
      //   "JSON.parse(this.cousewareInfo.locationSite.fileUrl"
      // );
      if (this.curItemInfo && this.curItemInfo.fileUrl) {
        let fileUrl = JSON.parse(this.curItemInfo.fileUrl);
        if (fileUrl) {
          // src = JSON.parse(this.cousewareInfo.locationSite.fileUrl).origin?.url;
          const urlArr = [];
          if (fileUrl.cd) {
            urlArr.push([dsf.url.getWebPath(fileUrl.cd.relative) + "?type=nccd", "video/" + this.getFileExt(fileUrl.cd.relative).substring(1), "清晰", "0", fileUrl.cd.default]);
          }
          if (fileUrl.sd) {
            urlArr.push([dsf.url.getWebPath(fileUrl.sd.relative) + "?type=ncsd", "video/" + this.getFileExt(fileUrl.sd.relative).substring(1), "标清", "1", fileUrl.sd.default]);
          }
          if (fileUrl.hd) {
            urlArr.push([dsf.url.getWebPath(fileUrl.hd.relative) + "?type=nchd", "video/" + this.getFileExt(fileUrl.hd.relative).substring(1), "高清", "2", fileUrl.hd.default]);
          }
          if (fileUrl.originM3u8) {
            urlArr.push([
              dsf.url.getWebPath(fileUrl.originM3u8.relative) + "?type=nchd",
              "video/" + this.getFileExt(fileUrl.originM3u8.relative).substring(1),
              "原画",
              "3",
              fileUrl.originM3u8.default,
            ]);
          }
          src = urlArr;
        }
        // else {
        //   src = null;
        // }
        // console.log(JSON.parse(this.cousewareInfo.locationSite.fileUrl));
      }
      return src;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    CoursePlays,
    gzplayer,
  },
  mounted() {},
  methods: {
    getPrefixText(item) {
      if (item) {
        const value = item["nc_learn_note_resourcelistsource.node_type_value"] || "video";
        const texts = {
          document: "文稿节点",
          video: "视频节点",
          audio: "音频节点",
          medium: "富媒体节点",
          link: "外链节点",
        };
        return texts[value];
      }
    },
    loadBaseInfo(id) {
      const sourceUrl = "nc/course/play/getPlayBase";
      let params = {
        courseId: id,
      };
      if (this.isView == 1) params = Object.assign(params, { isView: 1 });
      commoeLoadGet(sourceUrl, params, res => {
        this.baseInfo = res.data;
      });
    },
    updateProgress(data) {
      console.log(data, this.coursewareId, this.childreninfo[this.coursewareIndex]);
      this.childreninfo.forEach(item => {
        if (item.id === this.childreninfo[this.coursewareIndex]["id"]) {
          item.finishedRate = data.finishedRate;
        }
      });
    },
    async handleScreenshot() {
      this.$refs.gzplayer.playerEmitScrrenshot();
    },
    // 获取当前播放时间
    playerPlayingTime(e) {
      this.curTime = this.formatSeconds(e);
    },
    //秒转换时间格式并保存到store
    formatSeconds(value) {
      let _a = this.examTimerTime * 60;
      value = Math.floor(value);
      let _t = parseInt(value + 1);
      let data = dsf.date.secondsToTime(value);
      let time = data.hours + ":" + data.minutes + ":" + data.seconds;
      this.examPlayerNode = parseInt(value);
      if (_t % _a === 0) {
        if (!this.examTimer) {
          this.examTimer = setTimeout(() => {
            this.initExamTimer();
            this.examTimer = null;
            clearTimeout(this.examTimer);
          }, 2000);
        }
      }
      return time;
    },
    playerScreenshot(data) {
      console.log(data);
    },
    //当前视频播放结束
    async playEnd(state) {
      if (state == 2) {
        this.showPlayer = false;
        this.nextCourse();
      }
    },
    nextCourse() {
      this.playNextCourse();
    },
    playNextCourse() {
      let { childreninfo, curItemInfo } = this;
      let list = childreninfo || [];
      let idx = -1;
      _.each(list, (item, index) => {
        if (item.id == curItemInfo.id) {
          idx = index;
        }
      });
      if (idx !== -1) {
        ++idx;
        if (idx == list.length) idx = 0;
        // this.loadCurrentItem(list[idx]);
        this.coursewareIndex = idx;
        this.getPlayRecord(idx);
      }
    },
    // 比较当前时间在不在范围内
    isDuringDate(beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    setIfarem() {
      let src = ``;
      if (this.cousewareInfo.locationSite) {
        if (!this.cousewareInfo.locationSite.fileAdditionUrl || this.cousewareInfo.locationSite.fileAdditionUrl == "[]") {
          src = "";
        } else if (JSON.parse(this.cousewareInfo.locationSite.fileAdditionUrl)) {
          src = `http://${location.host}/preview/file?url=http://61.243.3.22:8089${JSON.parse(this.cousewareInfo.locationSite.fileAdditionUrl)[0].relativePath}`;
        } else {
          src = "";
        }
      }

      return src;
    },
    getFileExt(filePath) {
      if (filePath != "" && filePath) {
        if (filePath.indexOf("?") > -1) {
          filePath = filePath.split("?")[0];
        }
        var pos = "." + filePath.replace(/.+\./, "");
        if (pos.toLowerCase() !== ".m3u8") {
          // dsf.layer.pc.message("当前课程切片出现问题，为保证播放不出现卡顿，请联系管理员");
          console.log("当前课程切片出现问题，为保证播放不出现卡顿，请联系管理员");
        }
        return pos.toLowerCase();
      }
      return "";
    },
    // 获取专栏信息
    getClassInfo() {
      let that = this;
      const mainUrl = "/nc/pack/channel/info";
      const params = {
        id: this.$route.query.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.columninfo = res.data;
            //是否公开
            // if (res.data.sfgkValue == 0) {
            //   window.close();
            // }
            that.sfgkValue = res.data.sfgkValue; //"1" "0"
          } else {
            if (res.message == "专栏已停用") {
              return dsf.layer.alert("当前专栏已停用，请尝试学习其他专栏").then(res => {
                window.close();
              }).catch(err =>{
                that.$open({
                  url: `#/pc/nc/pagechannel/channelList`
                })  
              })
            }
            if (res.message == "用户id不存在") {
              if (!dsf.getCookie("userId")) {
                return dsf.layer.alert("当前专栏需要登录后才能学习，点击确定进行登录").then(res => {
                  that.$checkLogin().then(res => {
                    if (res) {
                      dsf.http
                        .get("/nc/notice/loginPopupNotice", {})
                        .done(res => {
                          if (!res.success) {
                            this.error(res);
                          } else {
                            let { data } = res;
                            if (data.content) {
                              sessionStorage.setItem("systemStatus", 1);
                            }
                          }
                        })
                        .error(err => {
                          console.log(err);
                        })
                        .always(res => {});
                    }
                  });
                }).catch(err =>{
                  window.location.replace(dsf.config.setting_public_pcindex_url);
                })
              }
              return dsf.layer.alert("您暂时没有权限学习本专栏，请尝试学习其他专栏").then(res => {
                window.close();
              }).catch(err =>{
                that.$open({
                  url: `#/pc/nc/pagechannel/channelList`
                })  
              })
            }
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 是否获取勋章
    getClassMedal() {
      let that = this;
      const mainUrl = "/nc/pack/channel/medal";
      const params = {
        id: this.$route.query.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if(res.success){
            that.isMedal = res.data.flag == 1 ? true : false;
          }
        })
        .error(function (error) {
        })
        .always(function () {
        });
    },
    // 数字大写
    numberToUpperCase(textIndex) {
      let newString = "";
      let newTextIndex = textIndex + 1 + "";

      function sum(value, index) {
        var newValue = "";
        if (textIndex === 9) {
          return !index ? "十" : "";
        }
        let isSeat = ~~textIndex > 9 && ~~textIndex < 19;
        switch (~~value) {
          case 1:
            newValue = !index ? (isSeat ? "" : "一") : "十一";
            break;
          case 2:
            newValue = !index ? (isSeat ? "" : "二") : "十二";
            break;
          case 3:
            newValue = !index ? (isSeat ? "" : "三") : "十三";
            break;
          case 4:
            newValue = !index ? (isSeat ? "" : "四") : "十四";
            break;
          case 5:
            newValue = !index ? (isSeat ? "" : "五") : "十五";
            break;
          case 6:
            newValue = !index ? (isSeat ? "" : "六") : "十六";
            break;
          case 7:
            newValue = !index ? (isSeat ? "" : "七") : "十七";
            break;
          case 8:
            newValue = !index ? (isSeat ? "" : "八") : "十八";
            break;
          case 9:
            newValue = !index ? (isSeat ? "" : "九") : "十九";
            break;
          case 0:
            newValue = "十";
            break;
          default:
            break;
        }
        return newValue;
      }

      for (let i = 0; i < newTextIndex.length; i++) {
        newString += sum(newTextIndex.substring(i, i + 1), i);
      }
      return newString;
    },
    // 收起延迟
    settimeout(note) {
      if (note) {
        this.noteHide = true;
      }
      // 章节先收起
      this.rightIshide = false;
      setTimeout(() => {
        // 视频放大
        this.video_minnwidth = false;
      }, 800);
      this.suspension = 0;
    },

    // 获取单元信息
    getUnitData(type, index) {
      let that = this;
      const mainUrl = "/nc/pack/channel/course/list";
      const params = {
        id: this.$route.query.id,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(async function (res) {
          if (res.success) {
            if (type == 1) {
              // 切换单元对于的课程
              // res.data[index].subList.forEach(item => {
              //   if (that.isDuringDate(item.timeSdate, item.timeEdate)) {
              //     item.isTrue = true;
              //   } else {
              //     item.isTrue = false;
              //   }
              // });
              that.classList = res.data[index].subList;
              that.curItem = res.data[index].subList[0];
              // 获取当前单元的第一个课
              await that.getPlayTrend(0);
              debugger;
            } else if (res.data.length > 0) {
              // 默认第一单元的课程列表
              // res.data[0].subList.forEach(item => {
              //   if (that.isDuringDate(item.timeSdate, item.timeEdate)) {
              //     item.isTrue = true;
              //   } else {
              //     item.isTrue = false;
              //   }
              // });

              that.classList = res.data[0]?.subList;
              that.curItem = res.data[0]?.subList[0];
              that.unitList = res.data;
              that.coursewareIndex = 0;
              await that.getPlayTrend(0);
            }

            // 默认显示第一个课程
            that.leftactive = 0;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 单元\课程切换
    async changeactive(index, type) {
      debugger;
      this.showPlayer = false;
      // 单元切换
      if (type == 1) {
        if (this.topactive != index) {
          this.getUnitData(1, index);
        }
        this.topactive = index;
        // 课程切换
      } else if (type == 0) {
        if (this.leftactive != index) {
          // 获取视频资源
          await this.getPlayTrend(index);
          debugger;
        }
        this.leftactive = index;
      }
    },
    // 获取视频、课件
    getPlayTrend(index) {
      let that = this;
      // 获取第几个课的id
      console.log(index, "第几个课程-获取视频、课件");
      let courseId = that.classList[index].businessId;
      that.curItem = that.classList[index];
      // this.$refs.theplay.loadCourses(courseId); 
      const mainUrl = "/inc/nc/course/play/getPlayTrend";
      const params = {
        courseId,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.cousewareInfo = res.data;
            that.childreninfo = that.cousewareInfo.playTree?.children;
            /* 如果上一个课件信息是空值，就取课件中的第一个 */
            let locationSiteId = that.cousewareInfo?.locationSite?.id || that.cousewareInfo?.playTree?.children[0]?.id;
            that.cousewareInfo?.playTree?.children.forEach((item, index) => {
              if (item.id == locationSiteId) {
                that.getPlayRecord(index);
                that.coursewareIndex = index;
                setTimeout(() => {
                  if (dsf.getCookie("user_id")) {
                    // that.showPlayer = true;
                    that.getNotList(that.value ? 1 : 0);
                  }
                }, 2000);
              }
            });
            // console.log(
            //   that.cousewareInfo.playTree.children,
            //   "that.cousewareInfo.playTree.children"
            // );
            if (that.suspension == 2) {
              console.log("==============222");
            }

            // console.log(
            //   that.cousewareInfo,

            //   "---------+++++++++---------"
            // );
          } else {
            dsf.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    // 切换课件激活索引
    changecoureswareIndex(index) {
      this.showPlayer = false;
      this.$refs.gzplayer.updateProgress();
      setTimeout(() => {
        this.coursewareIndex = index;
        this.getPlayRecord(index);
      }, 0);
    },
    // 章节点击
    unitclick() {
      this.suspension = 1;
      this.video_minnwidth = true;
      this.rightIshide = true;
      this.noteHide = true;
      this.manuscriptsHide = true;
      // 如果是单元切换
      // if (this.unitisChange) {
      //   console.log("单元切换");

      // }
    },
    // 文稿点击
    manuscriptsclick() {
      this.suspension = 2;
      // 播放器缩小
      this.video_minnwidth = true;
      //章节显示
      this.rightIshide = true;
      // 笔记展示
      this.noteHide = true;
      // 文稿展示
      this.manuscriptsHide = false;
      this.getPlayRecord(this.coursewareIndex);
      // if (this.cousewareInfo.locationSite.fileAdditionUrl == null) {
      //   this.manuscriptsHide = true;
      //   this.warnInfo = true;
      //   let time = setTimeout(() => {
      //     this.warnInfo = false;
      //   }, 900);
      //   clearTimeout(time);
      // }
      // this.warnimg();
    },
    // 笔记点击
    noteshow() {
      this.suspension = 3;
      this.video_minnwidth = true;
      this.rightIshide = true;
      this.noteHide = false;
      this.takenotes("我的");
    },
    // 提示信息
    warnimg() {
      // if (this.cousewareInfo.locationSite.fileAdditionUrl == null) {
      this.manuscriptsHide = true;
      this.warnInfo = true;
      setTimeout(() => {
        this.warnInfo = false;
      }, 900);
      // }
    },
    // 点击课件视频获取
    getPlayRecord(index) {
      // console.log(index, "点击课件 视频获取");
      // 激活样式
      let that = this;
      // 课程id
      let courseId = that.classList[that.leftactive].businessId;
      that.curItem = that.classList[that.leftactive];
      // console.log("课程", that.leftactive, "课件", that.coursewareIndex);
      console.log(that.childreninfo, index);
      if (that.childreninfo[index]) {
        var { id } = that.childreninfo[index];
      }

      that.loadBaseInfo(courseId);
      // 课件id
      // let coursewareId = id;
      const mainUrl = "/inc/nc/course/play/getPlayRecord";
      const params = {
        courseId,
        coursewareId: id,
        isView: this.isLogin ? 0 : 1,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data && res.data != {}) {
            //点击课件切换视频资源
            that.curItemInfo = res.data;
            console.log("res.data.fileUrl", res.data.fileUrl);
            that.cousewareInfo.locationSite.fileUrl = res.data.fileUrl;
            that.cousewareInfo.locationSite.fileAdditionUrl = res.data.fileAdditionUrl;
            // 发送课件id
            if (that.cousewareInfo.locationSite.fileUrl) {
              that.videoSrc = JSON.parse(that.cousewareInfo.locationSite.fileUrl).origin;
            }
            that.showPlayer = true;
            // that.loadBaseInfo(id);
            if (that.suspension == 2) {
              console.log(res.data.fileAdditionUrl, "");
              if (res.data.fileAdditionUrl) {
                that.manuscriptsHide = false;
                that.cousewareInfo.locationSite.fileAdditionUrl = res.data.fileAdditionUrl;
              }
              if (res.data.fileAdditionUrl == "[]" || !res.data.fileAdditionUrl) {
                that.warnimg();
                // 点击课件切换文稿
                that.cousewareInfo.locationSite.fileAdditionUrl = res.data.fileAdditionUrl;
              }
            }
            console.log(that.cousewareInfo, index, "获取课件资源2");
          } else {
            dsf.message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },
    takenotes(type) {
      if (!dsf.getCookie("userId")) {
        return false;
      }
      let that = this;
      const mainUrl = "/inc/meta/list/data";
      const params = {
        pageNum: 1,
        pageSize: 20,
        query: JSON.stringify({ searchValue: "" }),
        order: [],
        namespace: "nc.learn.note",
        pageName: "resourcelist",
        relationId: this.classList[this.leftactive]["businessId"],
      };
      if (type == "我的") {
        params.isOwn = 1;
      } else {
        params.isAll = 1;
      }
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            console.log(res.data, "123");
            that.notesList = res.data;
          }
        })
        .error(function (error) {
          // that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          // that.dsf.layer.closeLoading(loader);
        });
    },

    save() {
      let that = this;
      if (!dsf.getCookie("userId")) {
        return dsf.layer.alert("当前专栏需要登录后才能学习，点击确定进行登录").then(res => {
          that.$checkLogin().then(res => {
            if (res) {
              dsf.http
                .get("/nc/notice/loginPopupNotice", {})
                .done(res => {
                  if (!res.success) {
                    this.error(res);
                  } else {
                    let { data } = res;
                    if (data.content) {
                      sessionStorage.setItem("systemStatus", 1);
                    }
                  }
                })
                .error(err => {
                  console.log(err);
                })
                .always(res => {});
            }
          });
        });
      }
      console.log();
      const mainUrl = "/inc/nc/learn/note/save";
      const params = {
        relationId: this.classList[this.leftactive]["businessId"], //课程id
        nodeTime: this.curTime, //时间节点
        nodePicture: this.coverImage || "", //视频截图
        nodeWords: this.noteContent, //笔记内容
        isPublic: this.value ? 1 : 0, //
        rTypeValue: this.classList[this.leftactive]["typeValue"], //
        relationTitle: this.classList[this.leftactive]["name"], //
        nodeTypeText: "视频",
        nodeTypeValue: "video",
      };
      that.dsf.http
        .post(mainUrl, params)
        .done(res => {
          if (res.success) {
            this.noteContent = "";
            this.getNotList(this.value ? 1 : 0);
          }
        })
        .error(function (error) {})
        .always(function () {});
    },
    getNotList(type) {
      if (!dsf.getCookie("userId")) {
        return false;
      }
      var _this = this;
      var loading = dsf.layer.loading();
      //xxx为请求地址，key为参数名，value参数值
      let params = {
        pageNum: 1,
        pageSize: 300,
        query: "{searchValue:''}",
        order: "[]",
        namespace: "nc.learn.note",
        pageName: "resourcelist",
        relationId: this.classList[this.leftactive]["businessId"],
      };
      if (type == 2) {
        params["isAll"] = 1;
      } else {
        params["isOwn"] = 1;
      }
      this.$http
        .get("/inc/meta/list/data", params)
        .done(res => {
          if (res.success) {
            //查询成功
            _.each(res.data, item => {
              item.readOnly = true;
            });
            this.noteList = res.data;
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          //不管成功失败都会执行（请求，比如404，200）
          dsf.layer.closeLoading(loading);
        });
    },
    loadNoteList(state) {
      if (!dsf.getCookie("userId")) {
        return false;
      }
      const sourceUrl = "meta/list/data";
      const params = {
        pageNum: ++this.loadCount,
        pageSize: 20,
        query: "{searchValue:''}",
        order: "[]",
        namespace: "nc.learn.note",
        pageName: "resourcelist",
        relationId: this.relationId,
      };
      dsf.http
        .get(sourceUrl, Object.assign(params, this.tempParams), $$webRoot.nc)
        .done(({ success, data }) => {
          if (success) {
            if (data.length < params.pageSize) this.hasLoaded = true;
            _.each(data, item => {
              item.readOnly = true;
            });
            this.noteList = data;
            this.setNoteListHeight();
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {});
    },
    setNoteListHeight() {
      this.$nextTick(function () {
        const it = this.$refs.noteList;
        let winH = window.innerHeight;
        let itTop = it.offsetTop;
        let itH = it.offsetHeight;
        if (winH - itTop < itH) {
          this.$refs.noteList.style.height = winH - itTop - 30 + "px";
          this.$refs.noteList.style.overflowY = "scroll";
        }
      });
    },
    saveNote(idx, data) {
      // console.log(this.item);
      // console.log(this.curItem);
      this.$checkLogin().then(res => {
        if (res) {
          let item = this.form;
          if (item.desc == "" && this.tempText == "") return dsf.layer.pc.message("笔记内容不能为空", false);
          if (this.tempText.length > 1000) return dsf.layer.pc.message("笔记内容超长，请重新输入", false);
          const sourceUrl = "nc/learn/note/save";
          console.log(this.curItemInfo);
          let params = {
            relationId: this.classList[this.leftactive]["businessId"],
            nodeTime: this.getCurPlayTime,
            nodePicture: this.coverImage || "",
            nodeWords: item.desc,
            isPublic: item.public ? 1 : 0,
            rTypeValue: "course",
            relationTitle: this.classList[this.leftactive]["name"] ? this.classList[this.leftactive]["name"] : this.item.title,
            nodeTypeText: this.curItemInfo.rTypeText || "",
            nodeTypeValue: this.curItemInfo.rTypeValue || "",
          };
          if (data) {
            //修改笔记 传入当前笔记id
            params = Object.assign(params, {
              id: this.noteList[idx]["_id"],
              nodeWords: this.tempText,
            });
          }
          dsf.http
            .post(sourceUrl, params, $$webRoot.nc)
            .done(res => {
              if (res.success) {
                this.loadCount = 0;
                this.getNotList(this.value ? 1 : 0);
                this.tempText = "";
                this.form.desc = "";
                this.isDisabled = true;
                dsf.layer.pc.message("保存成功", true);
              } else {
                dsf.layer.pc.message(res.message, false);
              }
            })
            .error(err => {
              dsf.layer.pc.message(err.message, false);
            })
            .always(res => {
              // dsf.layer.pc.closeLoading(loading);
            });
        }
      });
    },
    delNote(item) {
      const sourceUrl = "nc/learn/note/delete";
      let params = {
        ids: JSON.stringify(item._id),
      };
      dsf.http
        .post(sourceUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.getNotList(this.value ? 1 : 0);
            this.tempText = "";
            dsf.layer.pc.message("删除成功", true);
          } else {
            dsf.layer.pc.message("删除失败", false);
          }
        })
        .error(err => {
          dsf.layer.pc.message(err.message, false);
        })
        .always(res => {
          // dsf.layer.pc.closeLoading(loading);
        });
    },
    updateItem(idx) {
      this.noteList[idx].readOnly = false;
      this.tempText = this.noteList[idx]["nc_learn_note_resourcelistsource.node_words"];
    },
    setScrrenshot(item) {
      const data = item;
      let src = "";
      if (dsf.isDef(data) && data[0]) {
        src = data[0].photo ? dsf.url.getWebPath(JSON.parse(data[0].photo)[0].relativePath) : "";
      }
      return src;
    },
    // 我的笔记 全部笔记切换
    filterNote(text) {
      if (text == "我的") {
        this.tempParams = {
          isOwn: 1,
        };
      } else {
        this.tempParams = {
          isAll: 1,
        };
      }
      this.loadCount = 0;
      this.loadNoteList();
    },
  },
});
function commoeLoadGet(sourceUrl, params = {}, succFn, failFun, method = "get") {
  // const loading = dsf.layer.pc.loading();
  dsf.http
    .get(sourceUrl, params, $$webRoot.nc)
    .done(res => {
      res.success && succFn && succFn(res);
      // if (!res.success) error(res);
    })
    .error(err => {
      error(err);
    })
    .always(res => {
      // dsf.layer.pc.closeLoading(loading);
    });
  function error(err) {
    failFun && failFun(err);
    dsf.layer.pc.message(err.message, false);
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-switch__label {
  position: absolute;
  display: none;
  font-weight: normal;
}

/deep/ .el-switch__label * {
  font-size: 12px;
}

/deep/ .el-switch__label--left {
  z-index: 9;
  left: 32px;
  color: #fff !important;
}
.dsf_nc_note_item .dsf_nc_note_item_inner .note-list .note-list-item-top,
.dsf_nc_note_item .dsf_nc_note_item_inner .note-list .note-list-item-top span:last-child,
.dsf_nc_note_item .dsf_nc_note_item_inner .note-list .note-list-item .note-list-item-desc .note-info span,
.dsf_nc_note_item .dsf_nc_note_item_inner .note-list .note-list-item .note-list-item-desc .note-info .node-text {
  color: #333;
}
.dsf_nc_note_item .dsf_nc_note_item_inner .note-list {
  padding: 0 4px !important;
}
.note-list-wrap {
  overflow-x: inherit !important;
  ::-webkit-scrollbar {
    width: 0px !important;
    display: none !important;
  }
}
/deep/ .el-switch__label--right {
  z-index: 9;
  color: #fff !important;
}

/deep/ .el-switch__label.is-active {
  display: block;
  height: 25px;
  line-height: 25px;
}

/deep/ .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px !important;
}

.el-switch,
.el-switch__core {
  height: 25px;
  line-height: 25px;
}

.el-switch__core {
  border-radius: 15px;

  &:after {
    width: 20px;
    height: 20px;
    top: 4px;
    left: 3px;
    z-index: 10;
  }
}
.gray {
  color: #606266 !important;
}
.el-switch.is-checked .el-switch__core::after {
  margin-left: -23px;
}
#J_noteList {
  width: 326px;
}
</style>
