<template v-if="isreload">
  <div class="ds-control ds-navtab ds-side-navtab xlyclass" :class="{ 'ds-no-padding': !showPadding }" :style="{ height: isDesign ? '100%' : tabHeight }" id="tab-pane">
    <div class="sidebartitle">{{title}}</div>

    <el-tabs
      v-model="activeName"
      :tab-position="'left'"
      :type="tabStyle == 'customer-tab-pad' ? '' : tabStyle"
      :closable="enableDelete"
      @tab-click="tabClick"
      @tab-remove="tabRemove"
      :before-leave="beforeLeave"
    >
      <slot></slot>
      <el-tab-pane
        v-for="d in slots.filter(tab => {
          return isHide(tab) !== true;
        })"
        :key="d.name"
        :label="d.title"
        :name="d.name"
        :slot-name="d.name"
        :lazy="isLazy(d)"
        :style="{ padding: $padding }"
      >
        <span class="slot" slot="label">
          <i :class="`icon iconfont ${d.name}`"></i>
          <span class="clie" v-if="d.title == '公告' && unreadCount > 0">{{ unreadCount }}</span>
          {{ d.title }}
          <span v-if="d.badge !== null" class="el-badge" v-html="d.badge"></span>
        </span>
        <slot :name="d.name" v-if="isDesign"></slot>
        <template v-if="activeName == d.name && !isDesign" class="slot-box">
          <slot :name="d.name"></slot>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default dsf.component({
  name: "DsfNcXlySideBar",
  ctrlCaption: "贵州训练营侧边导航",
  mixins: [$mixins.tabControl],
  props: {
    title: {
      type: String,
      default: "训练营",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    moduleWidth: {
      type: String,
      default: "220px",
    },
    moduleHeight: {
      type: String,
      default: "840px",
    },
    active: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isreload: true,
      tabList: [
        {
          name: "课程",
          icon: "menu-item-icon-kc",
        },
        {
          name: "资料",
          icon: "menu-item-icon-zl",
        },
        {
          name: "考试",
          icon: "menu-item-icon-ks",
        },
        {
          name: "签到",
          icon: "menu-item-icon-qd",
        },
        {
          name: "问卷",
          icon: "menu-item-icon-wj",
        },
        {
          name: "排名",
          icon: "menu-item-icon-pm",
        },
        {
          name: "公告",
          icon: "menu-item-icon-gg",
        },
        {
          name: "班主任信箱",
          icon: "menu-item-icon-bzrxx",
        },
        {
          name: "班级社区",
          icon: "menu-item-icon-bjsq",
        },
      ],
      activeName: this.$route.query.active || "",
      styleDom: "",
      stateList: [],
      btnText: "",
    };
  },
  components: {},
  computed: {
    ...mapState(["unreadCount", "coursestate", "isstudent"]),
    ...mapGetters(["getcoursestate"]),
  },
  created() {
    let that = this;
    eventBus.$on("reloadsildbar", data => {
      that.btnText = data;
      that.isreload = false;
      setTimeout(() => {
        that.getState();
        that.isreload = true;
      }, 0);
    });
  },
  mounted() {
    this.getunreadCount();
    this.getState();
    this.getIsStudent();
    let styleDom = document.createElement("style");
    document.body.append(styleDom);
    this.styleDom = styleDom;
    this.updateStyleDom();
  },
  methods: {
    ...mapActions(["getunreadCount", "getState", "getIsStudent"]),
    updateStyleDom() {
      this.$nextTick(() => {
        let height = $("#tab-pane").height();
        if (height) {
          this.styleDom.innerHTML = `
            .ds-side-navtab .slot-box .ds-page-content,
            .ds-side-navtab .slot-box .ds-panel {
              min-height: ${height - 85}px;
            }
            .ds-side-navtab .slot-box .ds-empty-data{
              min-height: ${height - 268}px;
            }
          `;
        }
      });
    },
    beforeLeave(activeName, oldActiveName) {
      // if (this.btnText == "报名") return true;
      if (activeName == "0" || activeName == "class") return true;
      if (this.isstudent == 1 || this.isstudent == 2) return true;
      let that = this;
      let html = "";
      let state;
      switch (that.getcoursestate) {
        case -1:
          html = "请报名后再学习";
          state = false;
          break;
        case 0:
          html = "请报名后再学习";
          state = false;
          break;
        case 1:
          html = "待审核后学习";
          state = false;
          break;
        case 2:
          state = true;
          break;
        case 3:
          state = true;
          break;
        case 4:
          html = "审核中，审核后学习";
          state = false;
          break;
        case 5:
          html = "已拒绝学习";
          state = false;
          break;
        default:
      }
      if (html) {
        dsf.layer.message(html, false);
      }
      return state;
    },
    tabClick(index) {
      console.log(index["_uid"], "index");
      if (index["_uid"] == 39) {
        eventBus.$emit("gohome", 1);
      }
    },
  },
});
</script>
