<template>
  <div class="dsf_nc_educationlist" ref="edcution">
    <div class="header">
      <span>教学基地</span>
      <div class="search">
        <input
          type="text"
          placeholder="搜索"
          ref="search"
          :value="searchval"
          @input="upvalue"
          @keyup.enter="search"
        />
        <img
          src="~@/assets/styles/img/pcimg/guizhou/search.png"
          alt=""
          @click="search"
        />
      </div>
    </div>
    <div class="filter">
      <div class="row rowo">
        <span class="title">筛选：</span>
        <!-- 单选标签 -->
        <div class="tab tab1" v-if="tabdata">
          <!-- <span v-for="(item, index) in tabarr" :key="index"> -->
          {{ tabdata }}
          <!-- </span> -->
          <div class="icon_close" @click="colsetab">×</div>
        </div>
        <!-- 多选标签 -->
        <div class="tab tab2" v-if="tabarr.length && !tabdata">
          <span v-for="(item, index) in tabarr" :key="index">
            <span v-if="index == 0">{{ item }}</span>
            <span v-if="index != 0">、{{ item }}</span>
          </span>
          <div class="icon_close" @click="colsetab">×</div>
        </div>
      </div>

      <div class="row rowt" ref="rowt">
        <span class="title">机构：</span>
        <div class="organ">
          <span
            class="region"
            ref="regions"
            v-for="(item, index) in address"
            :key="index"
            style="cursor: pointer"
            @click="updatedata(item, index)"
            ><input
              @click="checkbtn(index)"
              type="checkbox"
              class="checkbox"
              :value="item"
              ref="input"
              v-if="ischeck"
              :id="item"
            />
            <span
              class="showbox"
              @click="checkbtn(index)"
              v-if="ischeck"
            ></span>
            <label :for="item" style="cursor: pointer">
              {{ item }}
            </label>
          </span>
        </div>

        <div class="tab">
          <div class="btn" @click="checkout">
            <span style="font-size: 18px">＋</span><span>多选</span>
          </div>
        </div>
      </div>
      <div class="outside">
        <div class="conform" v-if="ischeck">
          <div class="isok" @click="isright">确定</div>
          <div class="iscolse" @click="isfalse">取消</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="godetail(item.nc_operate_jdwh_id)"
      >
        <div class="edlsimgbox">
          <img
            :src="setImg(item['nc_operate_jdwh.cover'] || item.cover)"
            alt=""
          />
        </div>
        <div class="desc">
          {{ item["nc_operate_jdwh.title"] || item.title }}
        </div>
      </div>
    </div>
    <el-pagination
      ref="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[12]"
      :page-size="12"
      layout="prev, pager, next,total, jumper"
      background
      :popper-class="edpagination"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcEducationList",
  ctrlCaption: "党性教育列表",
  mixins: [$mixins.control],
  data() {
    return {
      list: [],
      searchval: null,
      address: [
        "全部",
        "贵阳市",
        "遵义市",
        "安顺市",
        "毕节市",
        "铜仁市",
        "六盘水市",
        "黔西南布依族苗族自治州",
        "黔东南苗族侗族自治州",
        "黔南布依族苗族自治州",
      ],
      ischeck: false,
      tabarr: [],
      tabdata: null,
      total: null,
      currentPage4: 1,
      row: 12,
      page: 1,
      ishastotal: null,
    };
  },
  created() {
    this.showList("", [], this.page, this.row);
    this.getcount(this.row, this.page, "");
  },
  mounted() {},
  methods: {
    // 当前条数钩子
    handleSizeChange(val) {
      this.row = val;
      this.showList(
        this.searchval,
        this.tabarr || this.tabdata,
        this.page,
        val
      );
    },
    // 当前页数钩子
    handleCurrentChange(val) {
      this.page = val;
      this.showList(this.searchval, this.tabarr || this.tabdata, val, this.row);
    },
    // 移除数组指定位置的值
    removeByValue(arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
          arr.splice(i, 1);
          break;
        }
      }
    },
    newset(arr) {
      return arr
        .filter((item, index) => {
          return arr.indexOf(item) == index;
        })
        .sort((a, b) => a - b);
    },
    //清楚标签 机构样式
    removeclass() {
      this.$nextTick(() => {
        for (let i = 0; i < this.$refs.regions.length; i++) {
          this.$refs.regions[i].classList.remove("reactive");
        }
      });
      this.tabdata = null;
      this.tabarr = [];
    },
    // 多选框
    checkbtn(index) {
      // console.log(index);
      this.$nextTick(() => {
        this.$refs.input[0];
        if (this.$refs.input[index].checked) {
          // if (this.tabarr instanceof Array) {
          this.tabarr.push(this.$refs.input[index].value);
          this.tabarr = this.newset(this.tabarr);
          // }
        } else {
          this.removeByValue(this.tabarr, this.$refs.input[index].value);
        }
      });
    },
    // 标签关闭icon
    colsetab() {
      this.removeclass();
      this.showList(this.searchval, [], 1, 12);
      this.getcount(12, 1, this.searchval);
    },
    // 取消按钮
    isfalse() {
      this.removeclass();
      this.ischeck = !this.ischeck;
    },
    // 确认按钮
    async isright() {
    this.showList(this.searchval, this.tabarr, 1, 12);
      if (this.tabarr instanceof Array) {
        if (this.tabarr.indexOf("全部") > -1) {
          this.getcount(12, 1, this.searchval, 1);
          this.showList(this.searchval, [], 1, 12);
        } else {
          this.getcount(12, 1, this.searchval);
        }
      }
      this.ischeck = !this.ischeck;
    },
    // 获取getCount
    getcount(pageSize, pageNum, title, all) {
      let that = this;
      const mainUrl = "/nc/jdwh/getCount";
      let params = {};
      if (all) {
        params = {
          title,
          pageNum,
          pageSize,
        };
      } else {
        params = {
          title,
          pageNum,
          pageSize,
          areas: that.tabdata || that.tabarr,
        };
      }
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data.count;
            console.log(that.total, "total2，");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    getSearchCount(title) {
      let that = this;
      let areas = that.tabdata || that.tabarr;
      const mainUrl = "/nc/jdwh/getCount";
      if (areas instanceof Array) {
        areas.forEach((element) => {
          if (element == "全部") {
            areas = [];
          }
        });
      }
      if (areas == "全部") {
        areas = [];
      }
      const params = {
        title,
        pageNum: 1,
        pageSize: 12,
        areas,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.total = res.data.count;
            console.log(that.total, "total");
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 多选按钮
    checkout() {
      this.ischeck = !this.ischeck;
      this.tabdata = null;
      this.$nextTick(() => {
        for (let i = 0; i < this.$refs.regions.length; i++) {
          this.$refs.regions[i].classList.remove("reactive");
          for (let j = 0; j < this.tabarr.length; j++) {
            if (this.$refs.regions[i].firstChild.value == this.tabarr[j]) {
              this.$refs.regions[i].firstChild.checked = true;
            }
          }
        }
      });
    },
    // 更新搜索值
    upvalue() {
      this.searchval = this.$refs.search.value;
    },
    // 搜索请求
    search() {
      if (!this.searchval.trim()) {
        this.removeclass();
      }
      this.showList(this.searchval.trim(), this.tabdata || this.tabarr, 1, 12);
      this.getSearchCount(this.searchval.trim());
    },
    // 点击机构切换
    updatedata(item, index) {
      if (!this.ischeck) {
        console.log(item, "tabdata");
        this.tabdata = item;
        // 激活样式
        this.$nextTick(() => {
          // console.log(this.$refs.regions);
          for (let i = 0; i < this.$refs.regions.length; i++) {
            this.$refs.regions[i].classList.remove("reactive");
            if (index == i) {
              this.$refs.regions[i].classList.add("reactive");
            }
          }
        });
        if (item == "全部") {
          console.log(item, "==全部");
          this.getcount(12, 1, this.searchval, 1);
          this.showList(this.searchval, [], 1, 12);
        } else {
          // 请求筛选
          this.showList(this.searchval, this.tabdata, 1, 12);
          this.getcount(12, 1, this.searchval);
        }
      }
    },
    setImg(cover) {
      let src = "";
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      }
      return src;
    },
    showList(title, areas, pageNum, pageSize) {
      // title, areas, pageNum, pageSize
      if (areas instanceof Array) {
        areas.forEach((element) => {
          if (element == "全部") {
            areas = [];
          }
        });
      }
      if (areas == "全部") {
        areas = [];
      }

      let that = this;
      const mainUrl = "/nc/jdwh/getList";
      const params = {
        title,
        areas,
        pageNum,
        pageSize,
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.list = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    godetail(id) {
      window.open(`${location.pathname}#/pc/nc/page/pc/education/detail?id=${id}`);
    },
  },
});
</script>

<style></style>
