import "../dsf_config";
//全局样式
import "../assets/styles/pc/dsfStyle.scss";

//注入本地元数据
import "../metadata";
//注入入本地api
import "../api";
//引入本项目所有pc组件
// import vConsole from "vconsole";
import pcComponent from "./components";
import checkLoginState from "../common/checkLoginState";
import toExamPage from "../common/toExamPage";
import ddPlatform from "./common/ddPlatform";
import App from "./App.vue";
import store from "../store";
import router from "./router";
import i18n from "../i18n";
// echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// vant
import 'vant/lib/index.css';
import {
  Overlay
} from 'vant';
Vue.use(Overlay);
//swiper 
// import VueAwesomeSwiper from "vue-awesome-swiper";
// Vue.use(VueAwesomeSwiper)

import themesConfig from "_project/assets/styles/common";
Vue.use(pcComponent);
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// Vue.prototype.$vConsole = new vConsole();
let EventBus = new Vue();
//注入WEBAPI
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    },
  },
});

dsf.init.initPlatform(Vue);
dsf.init.globalMixin(Vue);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.$checkLogin = checkLoginState;
Vue.prototype.$toExamPage = toExamPage;
Vue.prototype.$ddPlatform = ddPlatform;

window.eventBus = new Vue();
// 登录页地址处理,重写首页地址，新增分平台，不止是ncIndex.html
let pathname = window.location.pathname;
let sessionPathName = dsf.storage.session.get('pathname')
let pathnameArr = ['/page.html', '/index.html'];
let pc_login_page = pathnameArr.includes(pathname) ? (sessionPathName || `/ncIndex.html`) : pathname;
dsf.config.setting_public_pc_login_page = pc_login_page + '#/pc/nc/pagehome/index'; 
dsf.config.setting_public_pcindex_url = pc_login_page + '#/pc/nc/pagehome/index';
let loginUrl = dsf.config.setting_public_pc_login_page;



router.beforeEach((to, from, next) => {
  //dingding和govDingding不展示

  const ua = navigator.userAgent;
  if (ua.toLowerCase().indexOf("dingtalk") === -1) {
    /*  if (dsf.getCookie("reset_pwd")) {
       //弱密码强制修改密码
       dsf.layer.openDialog({
         title: "当前密码较弱，请修改密码",
         width: "600px",
         height: "390px",
         showClose: false,
         showCancelButton: false,
         closeOnClickModal: false,
         closeOnPressEscape: false,
         params: {
           path: "/pc/nc/page/pc/login/changePwd?hideCancelButton=1",
         },
       });
     } */
  }

  /* 判断该用户信息填写到哪个步骤了 */
  if (Number(dsf.getCookie("flag"))) {
    //弱密码强制修改密码
    window.openInfo = dsf.layer.openDialog({
      title: "修改密码",
      width: "450px",
      height: "450px",
      showClose: false,
      showCancelButton: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      params: {
        path: "/pc/nc/page/pc/login/changePwd",
      },
    });
  } else if (dsf.getCookie("verificationText") && !Number(dsf.getCookie("verificationText"))) {
    dsf.layer.openDialog({
      title: "填写手机号后四位",
      width: "450px",
      height: "460px",
      showClose: false,
      showCancelButton: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      params: {
        path: "/pc/nc/page/pc/login/phonehsw",
      },
    });
  }

  // 非登录页，且需要登录，跳转到登录页面
  const notLogin = ["pcNcPagePcCommonpageClasscolumn"];  /* 如果存在不需要登录的页面，可以直接在这里写入对象路由的name */
  if ((to.meta.needLogin || to.fullPath == "/") && !notLogin.includes(to.name) && !dsf.getToken()) {
    let url = loginUrl + (to.fullPath !== "/" ? "?from=" + encodeURIComponent(location.href) : "");
    next(false);
    location.replace(url);
    return;
  }
  //通过路由的title修改页面标题
  // document.title = "融合培训平台"
  document.title = to.meta.title || dsf.config.setting_public_logo_title || "";
  //优先判断路由是否存在
  next();
});

new Vue({
  mixins: [dsf.$systemGlobalSetting(themesConfig)],
  store,
  router,
  i18n,
  created() {
    //监听window窗体变化后，递归组件执行组件的resize函数
    dsf.resizeDetector();
    if (dsf.config.setting_nc_tenement_silence === "on") {
      document.body.style.filter = "grayscale(100%)";
    }
    //修复IE前进后退路由变化没效果的问题
    if ("-ms-scroll-limit" in document.documentElement.style && "-ms-ime-align" in document.documentElement.style) {
      window.addEventListener(
        "hashchange",
        () => {
          var currentPath = window.location.hash.slice(1);
          if (router.path !== currentPath) {
            router.replace(currentPath);
          }
        },
        false
      );
    }
  },
  render: h => h(App),
}).$mount("#app");