<template>
  <div class="dsf_nc_special">
    <!-- 专题班 -->
    <div class="Special">
      <div class="imgbox">
        <img :src="imgUrl1" @click="goztb" alt="" />
      </div>
      <!-- <div class="content">
        <div class="infos" v-for="(item, index) in ztblist" :key="index" @click="goztbdetail(item['_id'])">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">
            {{ item.name }}
          </div>
        </div>
      </div> -->
    </div>
    <!-- 训练营 -->
    <div class="Special">
      <div class="imgbox">
        <img :src="imgUrl2" @click="goxly" alt="" />
      </div>
    <!--   <div class="content">
        <div class="infos" @click="goxlydetail(item['_id'])" v-for="(item, index) in xlylist" :key="index">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">{{ item.name }}</div>
        </div>
      </div> -->
    </div>
    <!-- 党员 -->
    <div class="Special" @click="gody">
      <div class="imgbox">
        <img :src="imgUrl3" alt="" />
      </div>
     <!--  <div class="content">
        <div class="content_jj">
          简介
        </div>
      </div> -->
    </div>
    <!-- 长征路 -->
    <div class="Special" @click="goczl">
      <div class="imgbox">
        <img :src="imgUrl4" alt="" />
      </div>
     <!--  <div class="content">
        <div class="content_jj">
          简介
        </div>
      </div> -->
    </div>
    <!-- 大讲堂-->
    <div class="Special" v-if="setting_nc_other_djt">
      <div class="imgbox">
        <img :src="imgUrl5" @click="goxlyNew" alt="" />
      </div>
      <!-- <div class="content">
        <div class="infos" @click="goxlydetailNew(item['_id'])" v-for="(item, index) in xlylist" :key="index">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">{{ item.name }}</div>
        </div>
      </div> -->
    </div>
    <div class="Special" v-else>
      <div class="imgbox">
        <img :src="imgUrl5" @click="godjt" alt="" />
      </div>
   <!--    <div class="content">
        <div class="infos" v-for="(item, index) in djtlist" :key="index" @click="godjtdetail(item['nc_live_info_id'])">
          <span class="left">{{ item.title }}</span>
          <span class="new" v-if="isnew(item.time_sdate)">new</span>
          <div class="ishover">
            {{ item.title }}
          </div>
        </div>
      </div> -->
    </div>
    <!-- 专栏 -->
    <div class="Special">
      <div class="imgbox">
        <img :src="imgUrl6" @click="gozl" alt="" />
      </div>
    <!--   <div class="content">
        <div class="infos" @click="gozldetail(item['_id'])" v-for="(item, index) in zllist" :key="index">
          <span class="left">{{ item.title }}</span>
          <div class="ishover">{{ item.title }}</div>
        </div>
      </div> -->
    </div>
  
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzSpecial",
  ctrlCaption: "贵州专题三合一",
  mixins: [$mixins.control],
  data() {
    return {
      djtlist: [],
      ztblist: [],
      xlylist: [],
      zllist: [],
     /*  imgUrl1: require("../../../assets/styles/img/pcimg/guizhou/b2-ztb.png"),
      imgUrl2: require("../../../assets/styles/img/pcimg/guizhou/b2-xly.png"),
      imgUrl3: require("../../../assets/styles/img/pcimg/guizhou/b2-djt.png"), */

      imgUrl1: require("../../../assets/styles/img/pcimg/guizhou/special_ztb.png"),
      imgUrl2: require("../../../assets/styles/img/pcimg/guizhou/special_xly.png"),
      imgUrl3: require("../../../assets/styles/img/pcimg/guizhou/special_dy.png"),
      imgUrl4: require("../../../assets/styles/img/pcimg/guizhou/special_czl.png"),
      imgUrl5: require("../../../assets/styles/img/pcimg/guizhou/special_djt.png"),
      imgUrl6: require("../../../assets/styles/img/pcimg/guizhou/jbpx.png"),
    };
  },
  created() {
/*     this.showXltList();
    this.showZtbList();
    this.showDjtList();
    this.showZlList(); */
  },
  computed: {
    setting_nc_other_djt(){
      return window.$$serverConfig.setting_nc_other_djt == '1';
    },
    setting_nc_other_dycth(){
      return window.$$serverConfig.setting_nc_other_dycth == '1';
    },
    setting_nc_other_czl(){
      return window.$$serverConfig.setting_nc_other_czl == '1';
    },
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
  },
  mounted() {},
  methods: {
    // 是否标新
    isnew(item) {
      if(!item) return;
      const newdate = new Date();
      const date = new Date(item.replace(/-/g, "/"));
      if (item) {
        if (newdate.getTime() - date.getTime() > 604800000) {
          return false;
        } else {
          return true;
        }
      }
    },
    //跳转详情
    goztbdetail(id) {
      if (this.isLogin) {
        window.open(`${location.origin}/page.html#/pc/nc/pagespecial/specialDetail?id=${id}`);
      } else {
        this.$checkLogin();
      }
    },
    goxlydetail(id) {
      if (this.isLogin) {
        window.open(`${location.origin}${location.pathname}#/pc/nc/page/pc/teachassistant/train?id=${id}`);
      } else {
        this.$checkLogin();
      }
    },
    goxlydetailNew(id) {
      if (this.isLogin) {
        window.open(`${location.origin}${location.pathname}#/pc/nc/page/pc/teachassistant/classroom?id=${id}`);
      } else {
        this.$checkLogin();
      }
    },
    godjtdetail(id) {
      if (this.isLogin) {
        // dsf.layer.message("暂未开放，敬请期待");
        // return
        window.open(`${location.origin}/page.html#/pc/nc/pagelive/liveDetail?id=${id}`);
      } else {
        this.$checkLogin();
      }
    },
    async gozldetail(id){
      /* 获取当前专栏是否公开以及是否停用 */
      if(!id) return;

      let item = {
        url: `#/pc/nc/page/pc/commonpage/classcolumn?id=${id}`,
        opentype_value: 2,
      }
      let { success, message, data } = await this.getChannelState({ id });
      let state = data.state;
      if (state == 1) {
        dsf.layer.pc.message('专栏不存在', false);
      } else if (state == 2) {
        dsf.layer.pc.message('当前专栏已停用，请尝试学习其他专栏', false);
      } else if (state == 3) {
        /* 需要登录 */
        dsf.layer.alert('当前专栏需要登录后才能学习，点击确定进行登录').then((res) => {
          this.$checkLogin();
        });
      } else if (state == 0) {
        /* 0为能正常进入专栏 */
        if (this.isLogin) {
          /* 如果登录验证是否报名过专栏 */
          let { success, message, data } = await this.verifyColumnSign({ id });
          if (success && data.sign) {
            this.$open({
              url: item.url,
              state: item.opentype_value
            });
          } else {
            dsf.layer.confirm({ message: '请确认是否报名学习本专栏？', confirmButtonText: '确认' }).then(async (res) => {
              /* 报名 */
              let { success, message, data } = await this.columnSign({ id });
              if (success) {
                this.$open({
                  url: item.url,
                  state: item.opentype_value
                });
              }
            });
          }
        } else {
          this.$open({
            url: item.url,
            state: item.opentype_value
          });
        }
      }
    },
    goztb() {
      if (this.isLogin) {
        window.open(`${location.pathname}#/pc/nc/pagespecial/specialList`);
      } else {
        this.$checkLogin();
      }
    },
    goxly() {
      if (this.isLogin) {
        window.open(`${location.pathname}#/pc/nc/class/train/list`);
      } else {
        this.$checkLogin();
      }
    },
    goxlyNew() {
      if (this.isLogin) {
        window.open(`${location.pathname}#/pc/nc/class/train/list?type=djt`);
      } else {
        this.$checkLogin();
      }
    },
    godjt() {
      if (this.isLogin) {
        // dsf.layer.message("暂未开放，敬请期待");
        //   return
        window.open(`${location.pathname}#/pc/nc/page/pc/training/list`);
      } else {
        this.$checkLogin();
      }
    },
    gozl(){
      if (this.isLogin) {
        window.open(`${location.pathname}#/pc/nc/class/basetrain/home/class`);
      } else {
        this.$checkLogin();
      }
    },
    goczl(){
      if (this.isLogin) {
        if(this.setting_nc_other_czl){
          this.$open({
            url: location.pathname + '#/pc/nc/page/pc/longmarch/map',
            state: 2,
          })
        }else{
          dsf.layer.message("暂未开放，敬请期待");
        }
      } else {
        this.$checkLogin();
      }
    },
    gody(){
      if (this.isLogin) {
        if(this.setting_nc_other_dycth){
          this.$open({
            url: '#/pc/nc/page/pc/commonpage/classcolumn?id=a3f154bd3c24432c95ce5135479f127a',
            state: 2,
          })
        }else{
          dsf.layer.message("暂未开放，敬请期待");
        }
      } else {
        this.$checkLogin();
      }
    },
    // 专题班列表
    showZtbList() {
      let that = this;
      const mainUrl = "/nc/gz/home/ztb/list";
      const params = {
        type: 1,
        pageNum: 1,
        pageSize: 5,
        query: JSON.stringify({ searchValue: "" }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.ztblist = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 专栏
    showZlList() {
      let that = this;
      const mainUrl = "/dbsource/interface/nc/channel/data/homedata";
      const params = {
     
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.zllist = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 训练营列表
    showXltList() {
      let that = this;
      const mainUrl = "/nc/gz/home/ztb/list";
      const params = {
        type: 2,
        pageNum: 1,
        pageSize: 5,
        query: JSON.stringify({ searchValue: "" }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.xlylist = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 大讲堂列表
    showDjtList() {
      let that = this;
      const mainUrl = "/nc/gz/home/live/list";
      const params = {
        query: JSON.stringify({
          searchValue: "",
          ds_unit_id: '@[url("pid")]',
          real_state_value: ["2"],
        }),
        order: [],
        filter: [],
        namespace: "nc.live.info",
        pageName: "list",
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data.length > 0) {
            that.djtlist = res.data;
          } else {
            // that.$message({
            //   message: res.message || "获取列表数据异常",
            //   type: "warning",
            // });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    verifyColumnSign(params){
      const mainUrl = "/nc/pack/sign/valid";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
    columnSign(params){
      const mainUrl = "/nc/pack/sign/add";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
    getChannelState(params) {
      const mainUrl = "/nc/pack/getState";
      return new Promise((reslove, reject) => {
        this.dsf.http
          .get(mainUrl, params)
          .done(function (res) {
            if(res.success){
              reslove(res);
            }else{
              dsf.layer.pc.message(res.message, false);
              reject(res);
            }
          })
          .error(function (error) {
            dsf.layer.pc.message("服务器异常", false);
          })
          .always(function () {
          });
      });
    },
  },
});
</script>

<style></style>
