<template>
  <header class="dsf-college-home-header" :style="{ 'min-width': mainWidth + 20 + 'px' }">
    <div class="dsf-college-home-header-main" :style="{ width: mainWidth + 'px' }">
      <div class="dsf-college-home-header-site">
        <div class="logoBox">
          <img class="logo" :src="$logo | '../../../assets/styles/img/pcimg/guizhou/logo.png'" alt="" @click="design" />
          <span class="unit_name" v-if="unitName">({{unitName}})</span>
          <!-- <img class="logo" :src="'../../../assets/styles/img/pcimg/guizhou/logo.png'" alt="" @click="design" /> -->
          <!-- <img class="logo" src="../../../assets/styles/img/pcimg/guizhou/logo.png" alt="" @click="design" /> -->
          <van-overlay :show="show">
            <div class="wrapper">
              <div class="title">
                <span>设计理念</span>
                <span class="close" @click="closefn">×</span>
              </div>
              <div class="content">
                <p>贵州省党员干部网络学院的标识设计，主要融入了“贵”字的拼音首字母“G”、代表网络的英文字母“e”以及书本等元素， 通过艺术处理的方式将三种元素进行重构和融合而形成。</p>
                <p>“G”主要是代表贵州，寓意贵州省党员干部网络学院将始终坚持立足贵州， 紧密结合贵州实际，全力服务贵州党的建设和经济社会发展，着力为贵州党员干部教育培训提供平台支持。</p>
                <p>“e”是网络的代名词， 寓意贵州省党员干部网络学院将 深度契合网络时代新特点、新要求，依托各类网络载体开展党员干部教育培训，全力为贵州党员 干部打造一所网上党校。</p>
                <p>书本是知识的象征，寓意贵州省党员干部网络学院将努力为贵州广大党员干部开展学习提供优质平台，帮助 党员干部更加便捷有效地获取知识 、提升自我，服务全省高素质专业化干部队伍建设。</p>
                <p>
                  从整体来看，标识配色上选择的是党旗的红黄配色， 寓意贵州省党员干部网络学院将始终不忘初心、牢记使命，全面贯彻落实党的各项要求，全力服务党的事业发展。同时，标识整体像初升红日
                  ，而其中的书本也形似向着太阳翱翔的雄鹰，寓意贵州省党员干部网络学院将不畏艰难，勇于创新，不断升华，追求卓越，努力办成国内一流的党员干部网络学院。
                </p>
              </div>
            </div>
          </van-overlay>
          <a :href="indexUrl">
            <!-- <img class="logoname" src="../../../assets/styles/img/pcimg/guizhou/logo4.png" alt="" /> -->
            <!-- <img class="logo" :src="$logo | imgFormat" alt="" /> -->
          </a>
        </div>
        <div class="header-user">
          <home-search :placeholder="collegeRoot.searchPlaceholder" :search="collegeRoot.doSearch" @search="collegeRoot.$emit('search', $event)" />
          <!-- <template v-if="isLogin && dsf.cookies.get('user_name')"> -->
          <template v-if="isLogin && userName">
            <div class="header-user-icon" :style="{ 'background-image': `url(${$replace(defaultHeader)})` }">
              <img v-show="showIcon" :src="headIcongz | imgFormat" @error="headLoadError" @load="headLoadSuccess" />
            </div>
            <div v-if="!dropdownConfig.length" class="username">
              <!-- 欢迎您,{{ dsf.cookies.get("user_name") }} -->
              欢迎您,{{ userName }}
              <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
            </div>
            <el-dropdown v-else :show-timeout="0" placement="bottom" @visible-change="showDropdown = $event" @command="handleCommand" ref="usermuse">
              <div class="username">
                <!-- 欢迎您,{{ dsf.cookies.get("user_name") }} -->
                欢迎您,{{ userName }}
                <i v-if="dropdownConfig.length" :class="[showDropdown ? 'icon-shangjiantou' : 'icon-xiajiantou']" class="iconfont" />
              </div>
              <el-dropdown-menu slot="dropdown" ref="dropmenu">
                <el-dropdown-item v-for="(item, index) in dropdownConfig" :key="(item.key || '') + '__' + index" :command="index">
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <div
              class="shopping-cars"
              v-if="dsf.config.setting_nc_tenement_is_shop == 1"
              @click="toShoppingCars"
            >
              <i class="shopping-icon"></i>
            </div> -->
          </template>
          <template v-else>
            <template v-for="(bt, index) in customBts">
              <i :key="index" v-if="index" class="iconfont icon-vertical_line"></i>
              <a :key="index" class="link" @click="bt.handler()">{{ bt.name }}</a>
            </template>
          </template>
        </div>
      </div>
      <div v-show="showMenu" class="dsf-college-home-header-menu">
        <!-- <div class="dsf-college-home-header-menu-bar" :style="barStyle"></div> -->
        <div class="dsf-college-home-header-menu-list">
          <template v-for="(menu, index) in menuList">
            <div
              ref="menu"
              class="dsf-college-home-header-menu-item"
              :key="menu.menu_id"
              :style="{ position: 'relative' }"
              :class="{ active: activeMenuIndex === setindex(index) && menu.menu_name !== '学友圈' }"
              @click="collegeRoot._clickBefore(menuItemClick, menu, index)"
            >
              <span v-if="menu.menu_name !== '学友圈'">{{ menu.menu_name }}</span>
              <img v-else src="../../../assets/styles/img/pcimg/guizhou/nav-xyq.png" alt="" :style="{ width: '70px', height: '60px', position: 'absolute', zIndex: 0 }" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import menuUtils from "../utils";
import HomeSearch from "./homeSearch";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "DsfNcCollegeHomeHeader",
  inject: ["homeRoot", "collegeRoot"],
  components: {
    HomeSearch,
  },
  props: {
    unitName: {
      type: String,
      default: "",
    },
    mainWidth: {
      type: Number,
      default: 1200,
    },
    dropdownConfig: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否只响应最后一级菜单的点击事件
    lastLevelClick: {
      type: Boolean,
      default: false,
    },
    // 未登录状态下自定义按钮
    customBts: {
      type: Array,
      default() {
        return [];
      },
    },
    // // 未匹配到菜单时，是否自动隐藏菜单
    // isAutoHideMenu: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      html: `<img :src='../../../assets/styles/img/pcimg/guizhou/nav-xyq.png'/>`,
      show: false,
      headIcon: "",
      defaultHeader: dsf.config.setting_public_user_default_header,
      showIcon: false,
      showDropdown: false,
      /************/
      activeMenuIndex: -1,
      barStyle: { display: "none" },
      // loginState: dsf.getCookie("isLogin"),
    };
  },
  computed: {
    ...mapGetters(["userName"]),
    indexUrl() {
      return location.href.replace(/#.*/, "");
    },
    $logo() {
      return this.$replace(this.collegeRoot.logoUrl);
    },
    menuList() {
      return this.homeRoot.$menuList;
    },
    menuIndex() {
      return this.homeRoot.menuIndex || [];
    },
    showMenu() {
      if (!this.isAutoHideMenu) return true;
      return this.activeMenuIndex > -1 && this.activeMenuIndex < this.menuList.length;
    },
    isLogin() {
      return (dsf.getToken() && dsf.getToken() != "") || false;
    },
    headIcongz() {
      let headIcon = dsf.getCookie("photoRelativePath");
      headIcon = decodeURIComponent(headIcon) || "";
      return headIcon;
    },
  },
  watch: {
    menuIndex: {
      handler(to) {
        let index = to.length ? to[0] : -1;
        if (!this.isMounted) {
          this._activeMenuIndex = index;
        } else {
          this.activeMenuIndex = index;
          this.updateBarStyle();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initdewopmeun();
    // this.$refs.dropmenu.$el.style.width =
    // console.log(this.$refs, "11111");
    // this.initHeadIcon();
    let userName = dsf.getCookie("user_name");
    this.$store.state.userName = userName || "";
    this.getMuse();
    this.$nextTick(() => {
      // console.log(this.$refs.menu, "menu");
    });
  },
  mounted() {
    this.isMounted = true;
    if (this._activeMenuIndex !== undefined) {
      this.activeMenuIndex = this._activeMenuIndex;
      this.updateBarStyle();
    }
  },
  methods: {
    setindex(index) {
      if (index == 7) {
        return "";
      } else {
        return index;
      }
    },
    initdewopmeun() {
      this.$nextTick(() => {
        if (this.$refs.dropmenu) {
          this.$refs.dropmenu.$el.style.width = this.$refs.usermuse.$el.clientWidth + "px";
        }
      });
    },
    // }
    // 初始化头像，从cookie中获取
    initHeadIcon() {
      const headIcon = dsf.getCookie("photoRelativePath");
      if (headIcon) {
        this.headIcon = decodeURIComponent(headIcon);
      }
    },
    // 头像加载成功时
    headLoadSuccess() {
      this.showIcon = true;
    },
    // 头像加载失败时
    headLoadError() {
      this.showIcon = false;
    },
    // 用户头像下的菜单点击
    handleCommand(index) {
      const item = this.dropdownConfig[+index];
      this.$emit("dropdown-click", item);
    },
    updateBarStyle() {
      let l = this.menuList.length;
      if (this.activeMenuIndex < 0 || this.activeMenuIndex >= l) {
        this.barStyle = { display: "none" };
        return;
      }
      this.$nextTick(() => {
        let activeMenu = this.$refs.menu?.[this.activeMenuIndex];
        if (!activeMenu) {
          this.barStyle = { display: "none" };
        } else {
          let left = activeMenu.offsetLeft + activeMenu.offsetWidth / 2;
          this.barStyle = {
            transform: `translate(calc(${left}px - 50%), 0) scale(.5)`,
          };
        }
      });
    },
    menuItemClick(item, index) {
      // /pc/bbs/allPage?c=
      if (item.menu_name == '学友圈') {
        dsf.layer.message("暂未开放，敬请期待");
        return;
      }
      if ("#" + this.$route.fullPath == item.menu_url) {
        return dsf.layer.message("您已在当前页面，请勿重复点击！", "warning");
      }
      if (item.menu_opentype_value != 2) {
        this.activeMenuIndex = index;
      }
      if (!item.menu_url) {
        dsf.layer.message("暂未开放", false);
        return false;
      }
      if (this.lastLevelClick) {
        if ((!item.children || !item.children.length) && item.menu_url) {
          menuUtils.openPage.call(this.homeRoot, item);
        }
      } else {
        menuUtils.openPage.call(this.homeRoot, menuUtils.findMenu(item));
      }
    },
    toShoppingCars() {
      const path = dsf.config.setting_public_shoppingcars_url;
      path && dsf.layer.openWindow(path);
    },
    // 设计理念
    design() {
      this.show = true;
    },
    closefn() {
      this.show = false;
    },
    getMuse() {
      this.dsf.http
        .get("/nc/notice/loginPopupNotice", {})
        .done(res => {
          if (res.success) {
            let { data } = res;
            if (data.content) {
              if (sessionStorage.getItem("systemStatus") == 2) {
                this.systemAnnouncementShow = false;
              } else {
                sessionStorage.setItem("systemStatus", 1);
                this.systemAnnouncementShow = true;
                this.systemAnnouncementContent = data.content;
                this.systemAnnouncementTitle = data.title;
              }
            }
          }
        })
        .error(err => {})
        .always(res => {});
    },
  },
};
</script>
