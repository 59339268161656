<template>
  <div v-if="isDesign" class="ds-nc-base-train-card">
    <div class="cover">
      <dsf-image />
    </div>
    <div class="content">
      <div class="title ellipsis-2">
        <span>课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题课题</span>
      </div>
    </div>
    <div class="footer">
      <span>张三</span>
      <span>
        1234
      </span>
    </div>
  </div>
  <div v-else-if="objData" class="ds-nc-base-train-card" :title="data['title']" @click="_clickBefore(click)" :style="{ width: imgWidth }">
    <div class="cover" :style="{ width: imgWidth, height: imgHeight }">
      <dsf-image :src="objData['cover'] | imgFormat" :error-src="defaultSrc" />
    </div>
    <div class="content">
      <div class="title ellipsis">
        <span>{{ objData["name"] }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="course_period_title">
        开班总数：{{objData['total'] || 0}}
      </div>
      <span>
        进行中的班级数：{{ objData["going"] || 0 }}
      </span>
    </div>
  </div>
  <div v-else class="ds-nc-base-train-card" :title="data['title']" @click="_clickBefore(click)" :style="{ width: imgWidth }">
    <div class="cover" :style="{ width: imgWidth, height: imgHeight }">
      <dsf-image :src="data['cover'] | imgFormat" :error-src="defaultSrc" />
    </div>
    <div class="content">
      <div class="title ellipsis">
        <span>{{ data["name"] }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="course_period_title">
        开班总数：{{data['total'] || 0}}
      </div>
      <span>
        进行中的班级数：{{ data["going"] || 0 }}
      </span>
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcBaseTrainCard",
  ctrlCaption: "基本培训-班级卡片",
  mixins: [$mixins.card],
  data() {
    return {
      defaultSrc: dsf.config.setting_nc_image_default_img,
    };
  },
  props: {
    keysMap: {
      type: Array,
      default() {
        return [
          { from: "_id", to: "_id", type: "text", desc: "_id" },
          { from: "cover", to: "cover", type: "text", desc: "封面" },
          { from: "name", to: "name", type: "text", desc: "标题" },
          { from: "total", to: "total", type: "text", desc: "开班总数" },
          { from: "going", to: "going", type: "text", desc: "进行中的班级数" },
        ];
      },
    },
    // 跳转方式
    clickConfig: {
      type: Object,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    click() {
      if (this.$listeners.click) {
        this.$dispatch("click", this.objData);
      } else {
        if (!this.clickConfig) return;
        let { url, state } = this.clickConfig;
        url = this.$replace(url, this.objData);
        this.$open({ url, state });
        this.clickAfter();
      }
    },
  },
});
</script>
