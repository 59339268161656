<template>
  <div class="dsf_nc_home_channel">
    <div class="liveclass">
      <div class="title">
        <div class="imgbox">
          <img src="~@/assets/styles/img/pcimg/guizhou/bg/b2-zl.png" alt="" />
          <div class="more" @click="more">更多</div>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="godetail(item._id)">
          <div class="imgbox">
            <img :src="setImg(item['nc_channel_data_studyCenterChannelData.cover'])" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="bgimg">
      <img src="~@/assets/styles/img/pcimg/guizhou/bg/b2-bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default dsf.component({
  name: 'DsfNcHomeChannel',
  ctrlCaption: '首页专栏',
  mixins: [$mixins.control],
  data() {
    return {
      list: []
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    // 是否登录
    islogin() {
      this.$openDialog({
        title: '',
        noTitle: true,
        width: '800px',
        height: dsf.config.setting_nc_other_dx_login == '1' ? '430px' : '370px',
        class: 'test',
        params: {
          path: '/pc/nc/pagelogin/dialogLogin'
        }
      });
    },

    setImg(cover) {
      let src = '';
      if (cover) {
        src = JSON.parse(cover)[0].relativePath;
      } else {
        src = 'static/img/project/defaultCardImage.png';
      }
      return src;
    },
    godetail(id) {
      if (dsf.getCookie('isLogin')) {
        window.open(`/page.html#/pc/nc/page/pc/commonpage/classcolumn?id=${id}`);
      } else {
        this.islogin();
      }
   
    },
    more() {
      if (dsf.getCookie('isLogin')) {
        window.open(`${location.origin}/ncIndex.html#/pc/nc/pagechannel/channelList`);
      } else {
        this.islogin();
      }
    },
    initData() {
      let that = this;
      const mainUrl = '/meta/list/data';
      const params = {
        pageNum: 1,
        pageSize: 3,
        query: JSON.stringify({ searchValue: '' }),
        order: [],
        namespace: 'nc.page.app.onStudy',
        pageName: 'mobile-channelList'
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data.length > 0) {
            that.list = res.data;
          } else if (!res.success) {
            dsf.layer.message(res.message, false);
          }
        })
        .error(function (error) {
          dsf.layer.message('服务器异常', false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    }
  }
});
</script>

<style></style>
