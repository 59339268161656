<template>
  <div id="app">
    <home :page-suffix="pageSuffix"></home>
  </div>
</template>
<script href=""></script>
<script>
// import * as dd from "dingtalk-jsapi";
import { loadScript } from "./common/loadScript";
loadScript(dsf.url.getWebPath("static/libs/turn.min.js"));
import home from "./views/Home";
export default {
  components: {
    home,
  },
  provide() {
    return {
      $app: this,
    };
  },
  data() {
    return {
      pageSuffix: "else",
    };
  },
  created() {
    let htmlName = window.location.pathname;
    /* let route = ["_project", "_product", ""];
    let pageSuffix = "else";
    for (let i = 0; i < route.length; i++) {
      if (htmlName.indexOf(`/index${route[i]}.html`) >= 0) {
        pageSuffix = "index";
        break;
      } else if (htmlName.indexOf(`/index1${route[i]}.html`) >= 0) {
        pageSuffix = "index1";
        break;
      } else if (htmlName.indexOf(`/ncIndex${route[i]}.html`) >= 0) {
        pageSuffix = "ncIndex";
        break;
      } else if (htmlName.indexOf(`/aliIndex${route[i]}.html`) >= 0) {
        pageSuffix = "aliIndex";
        break;
      } else if (htmlName.indexOf(`/xcIndex${route[i]}.html`) >= 0) {
        pageSuffix = "xcIndex";
        break;
      } else if (htmlName.indexOf(`/zxqyIndex${route[i]}.html`) >= 0) {
        pageSuffix = "zxqyIndex";
        break;
      } else {
        pageSuffix = "else";
      }
      // window.dd = dd;
    }
    this.pageSuffix = pageSuffix; */
    this.pageSuffix = this.extractCharacters(htmlName);
  },
  methods: {
    extractCharacters(str) {
      // 使用正则表达式匹配最后一个 / 到第一个 . 之间的内容
      const regex = /[^/]+(?=\.[^.]+$)/;
      const match = str.match(regex);
      if (match) {
        // 从匹配到的字符串中提取出第一个单词
        return match[0].split('_')[0];
      }
      return 'else'; // 如果没有匹配到
    }
  },
};
</script>
