<template>
  <div class="dsf_specila_apply ds-control" :style="{ width: itemWidth, padding: itemPadding }">
    <div class="dsf_specila_apply-title">是否报名</div>
    <div class="dsf_specila_apply-content">
      <div class="item-row">
        <div class="dsf_specila_apply-item margin-right-30">
          <label class="item-title">允许报名</label>
          <el-switch v-model="allowed" :disabled="isview"></el-switch>
        </div>
      </div>
    </div>
    <div class="dsf_specila_apply-content" v-if="allowed">
      <div class="item-row margin-bottom-10 row-1">
        <div class="dsf_specila_apply-item margin-right-30 time">
          <label class="item-title required">报名时间</label>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            :disabled="isview"
          ></el-date-picker>
        </div>
        <div class="dsf_specila_apply-item peopleCheck">
          <el-checkbox :disabled="isview" v-model="reqParams.maxPeopleChecked"><label class="item-title">人数限制</label></el-checkbox>
          <el-input :disabled="isview" @input="handleInput" class="item-input" placeholder="请输入人数限制" v-model="reqParams.people" v-if="reqParams.maxPeopleChecked"></el-input>
        </div>
      </div>
      <!-- <div class="item-row margin-bottom-10">
        <div class="dsf_specila_apply-item">
          <label class="item-title">报名费用</label>
          <el-input class="item-input" placeholder="请输入报名费用" v-model="reqParams.money"></el-input>
        </div>
      </div> -->
      <div class="item-row margin-bottom-10" style="margin-bottom: 20px" v-if="rangeShow">
        <div class="dsf_specila_apply-item">
          <label class="item-title">报名范围</label>
          <el-radio-group v-model="rangeType" @change="changeRadio">
            <el-radio :label="0">认证校友</el-radio>
            <el-radio :label="1">所有人</el-radio>
            <el-radio :label="2">指定组织</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div :class="['insert', { 'insert-wrap': isDesign }]">
        <template v-for="(j, idx) in slots">
          <div :slot-name="j.name" :class="['insert-content', { 'insert-border': isDesign }]" :key="idx">
            <slot :name="j.name" v-if="isDesign || rangeType == 2"></slot>
          </div>
        </template>
      </div>
      <div class="item-row margin-bottom-10" style="margin-bottom: 20px" v-if="!isBasicTrain">
        <div class="dsf_specila_apply-item margin-right-30">
          <label class="item-title">报名审核</label>
          <el-switch v-model="reqParams.audit" :disabled="isview"></el-switch>
        </div>
      </div>

      <div class="item-row margin-bottom-10 item_flex">
        <div class="dsf_specila_apply-item margin-right-30">
          <label class="item-title">报名表单</label>
          <el-switch v-model="reqParams.form" :disabled="isview"></el-switch>
        </div>
        <div class="review" v-if="reqParams.form" @click="clickReview">预览</div>
      </div>
    </div>
    <div class="form-container" v-if="reqParams.form && allowed">
      <div class="container-left">
        <div class="title">已设置选项</div>
        <div class="input-row margin-bottom-10">
          <div class="input-width-70 input-title">报名者必填</div>
          <div class="input-width-100 input-title ell">报名选项</div>
          <div class="input-width-all input-title">报名者提示语</div>
        </div>
        <template v-for="(item, index) in reqParams.items">
          <div class="input-row margin-bottom-10" :key="index">
            <div class="input-width-70 input-title text-center"><el-checkbox v-model="item.required" :disabled="item.base || isview"></el-checkbox></div>
            <div class="input-width-100 input-title">{{ item.name }}</div>
            <div class="input-width-all input-title"><el-input :disabled="isview" class="item-input" v-model="item.text"></el-input></div>
            <dsf-icon name="icon_wrong" v-if="!item.base" class="deleteInput" @click="hanldeDeleteInput(item, index)"></dsf-icon>
          </div>
        </template>
      </div>
      <div class="container-right">
        <div class="title">添加选项</div>
        <div class="checked-add">
          <div class="common margin-bottom-10">常用项</div>
          <div class="checed-add-container margin-bottom-10">
            <el-checkbox-group :disabled="isview" v-model="commonCheckboxGroup" @change="handleChangeCommon" border>
              <el-checkbox-button :disabled="isview" v-for="item in common" class="margin-bottom-10 margin-5 checkbox-button" border :label="item.name" :key="item.type">
                {{ item.name }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div class="common margin-bottom-10">自定义项</div>
          <div :class="isview ? 'disable' : 'custom'">
            <template v-for="item in custom">
              <span class="custom-btn margin-5" :key="item.type" @click="!isview && handleCustom(item)">{{ item.text }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="dsf_specila_apply-btns text-center" v-if="btnsShow">
      <DsfButtonBar :disabled="isview" :buttons="buttons" :max-number="maxNumber" @save="sumbitForm" @clickBackSetp="clickBackSetp" style="display: inline-block" />
      <!-- <el-button v-if="backGo" @click="clickBackSetp">上一步</el-button> -->
      <!-- <el-button @click="cancelSumbit" icon="el-icon-close">关 闭</el-button> -->
      <!-- <el-button class="sumbit-btn" @click="sumbitForm">确 定</el-button> -->
    </div>
    <!-- dialog -->
    <el-dialog title="自定义选项" :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="beforeClose" custom-class="NcSpecialApplyDialog">
      <div class="dialog-content">
        <label class="item-title">报名选项</label>
        <el-input class="item-input" v-model="customItem.name"></el-input>
        <div class="content-add" v-if="customItem.type == 'radiobox' || customItem.type == 'checkbox'">
          <p class="hint">{{ customItem.type == "radiobox" ? "单选框" : "多选框" }}选项列表</p>
          <div class="add-list">
            <div class="add-input-box" v-for="(item, index) in checkboxList" :key="index">
              <el-input class="item-input require" required v-model="item.text"></el-input>
              <dsf-icon name="icon_wrong" class="deleteInput" v-if="!item.base" @click="deleteCheckbox(item, index)"></dsf-icon>
            </div>
          </div>
          <el-button class="add_btn" size="small" @click="addCheckbox">添 加</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default dsf.component({
  name: "DsfNcSpecialApplyGz",
  ctrlCaption: "专题班报名-贵州",
  mixins: [$mixins.layout],
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    itemWidth: {
      type: String,
      default: "850px",
    },
    buttons: {
      type: Array,
      default() {
        return [
          {
            actionName: "clickBackSetp",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            // icon: "icon-guanbi2",
            icon: "",
            id: "BeIBdsm19uqcoaVc",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "上一步",
            title: "",
          },
          {
            actionName: "save",
            caption: "自定义按钮",
            disabled: false,
            execing: false,
            flowSendOverEvent: "",
            icon: "icon-baocun",
            id: "Ld3EJ3ytFxJpgA3Q",
            if: "",
            isDblClick: false,
            isLinkSend: false,
            isLinkSendFold: false,
            isUpload: false,
            linkBind: "",
            params: [],
            syncRead: false,
            text: "确定",
            title: "",
          },
        ];
      },
    },
    maxNumber: {
      type: Number,
      default: 3,
    },
    url: {
      type: String,
      default: "nc/class/apply/save",
    },
    reqUrl: {
      type: String,
      default: "nc/class/apply/info",
    },
    itemPadding: {
      type: String,
      default: "15px 15px 15px 15px",
    },
    btnsShow: {
      type: Boolean,
      default: true,
    },
    backGo: {
      type: Boolean,
      default: false,
    },
    rangeShow: {
      type: Boolean,
      default: false,
    },
    tabData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    slots: {
      type: Array,
      default: () => {
        return [
          {
            name: "组织",
            controls: [],
          },
        ];
      },
    },
  },
  components: {},
  data() {
    return {
      allowed: false,
      commonCheckboxGroup: [],
      common: [
        { name: "公司", rule: "company", type: "textbox" },
        { name: "职位", rule: "post", type: "textbox" },
        { name: "部门", rule: "department", type: "ouaselect" },
        { name: "住址", rule: "address", type: "areaselect" },
        { name: "身份证", rule: "idCard", type: "textbox" },
        { name: "性别", rule: "sex", type: "radiobox" },
        { name: "年龄", rule: "age", type: "textbox" },
        { name: "真实姓名", rule: "userName", type: "textbox" },
        { name: "个人照片", rule: "photo", type: "uploadphoto" },
        { name: "省份城市", rule: "city", type: "areaselect" },
        { name: "邮箱", rule: "email", type: "textbox" },
      ],
      custom: [
        { text: "文本", rule: "text", type: "textbox" },
        { text: "单选", rule: "radio", type: "radiobox" },
        { text: "多选", rule: "checkbox", type: "checkbox" },
        { text: "图片", rule: "image", type: "uploadimg" },
      ],
      dialogVisible: false,
      customItem: { name: "", type: "textbox" },
      cacheArray: [],
      time: [],
      reqParams: {
        people: "",
        money: "",
        form: false,
        audit: false,
        maxPeopleChecked: false, //人数限制
        items: [
          { required: true, name: "参与人", text: "请输入参与人名称", base: true, rule: "name", type: "textbox" },
          { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
        ],
      },
      id: "",
      checkboxList: [{ text: "", value: 0, check: false, base: true }],
      isview: 0,
      rangeType: 0,
    };
  },
  watch: {
    tabData: {
      handler(val, val22) {
        console.log("handle", val);

        if (val.length) {
          val.map(item => {
            item.text = "请输入参与人" + item.name;
            item.base = false;
            item.required = false;
            let flag = this.common.some(i => {
              return i.name === item.name;
            });
            if (!flag) {
              this.common.push(item);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState(["isBasicTrain"]),
  },
  created(){
        this.getBasicTrain({id: this.$route.query.id});
  },
  mounted() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getData();
    }
    this.isview = this.$route.query.isview || this.queryString["isview"] || 0;
    if (this.isDesign) {
      this.reqParams.form = true;
    }
  },
  methods: {
    ...mapActions(["getBasicTrain"]),
    handleInput() {
      //基础培训最大限制500
      if (this.reqParams.people > 500 && this.isBasicTrain) {
        this.reqParams.people = 500;
      }
    },
    getData() {
      const loading = dsf.layer.loading();
      let params = {
        id: this.id || "",
      };
      this.dsf.http
        .get(this.reqUrl, params, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            this.reqParams = res.data;
            if (this.reqParams.allowed == undefined || this.reqParams.allowed == null) {
              this.allowed = false;
            } else {
              this.allowed = this.reqParams.allowed;
            }
            this.time = [this.reqParams.startTime || "", this.reqParams.endTime || ""];
            this.rangeType = this.reqParams.rangeType || 0;
            if (this.reqParams.items && this.reqParams.items.length > 2) {
              this.reqParams.items = JSON.parse(this.reqParams.items);
              this.reqParams.items.forEach((item, index) => {
                if (index > 1) {
                  let current = null;
                  this.custom.forEach(i => {
                    if (item.rule.indexOf(i.rule) !== -1) {
                      current = item;
                    }
                  });
                  if (current) {
                    this.cacheArray.push(current);
                  } else {
                    this.commonCheckboxGroup.push(item.name);
                  }
                }
              });
            } else {
              this.reqParams.items = [
                { required: true, name: "参与人", text: "请输入参与人名称", base: true, rule: "name", type: "textbox" },
                { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
              ];
            }
          } else {
            this.dsf.layer.message(res.message || "获取数据异常", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "获取数据异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    handleChangeCommon(val) {
      //构建对象
      this.reqParams.items = [
        { required: true, name: "参与人", text: "请输入参与人名称", base: true, rule: "name", type: "textbox" },
        { required: true, name: "手机号", text: "请输入参与人手机号", base: true, rule: "phone", type: "textbox" },
      ];

      if (this.commonCheckboxGroup.length) {
        let arr = [];
        this.commonCheckboxGroup.forEach(item => {
          let flag = this.reqParams.items.some(i => {
            return i.name == item;
          });
          if (!flag) {
            let current = this.common.filter(i => {
              return i.name == item;
            });
            let obj = { required: item.required, name: item, text: "请输入参与人" + item, base: false, rule: current[0].rule, type: current[0].type || "" };
            arr.push(obj);
          }
        });

        this.reqParams.items.push(...arr);
      }
      if (this.cacheArray.length) {
        this.reqParams.items.push(...this.cacheArray);
      }
    },
    hanldeDeleteInput(item, index) {
      if (!item) return;
      if (this.isview) return;
      let arr = JSON.parse(JSON.stringify(this.commonCheckboxGroup));
      this.commonCheckboxGroup.length &&
        this.commonCheckboxGroup.forEach((i, idx) => {
          if (item.name === i) {
            arr.splice(idx, 1);
          }
        });
      this.commonCheckboxGroup = arr;
      this.reqParams.items.forEach((j, idx) => {
        if (j.name == item.name) {
          this.reqParams.items.splice(idx, 1);
        }
      });
      // 查询缓存中是否有自定义并且删除
      this.cacheArray.forEach((j, idx) => {
        if (j.name == item.name) {
          this.cacheArray.splice(idx, 1);
        }
      });
    },
    //点击预览
    clickReview() {
      this.$openDialog({
        title: "预览报名表单",
        width: "520px",
        height: "auto",
        content: "DsfNcPersonalInformationForm",
        params: {
          getFormatData: this.reqParams.items,
        },
        closed: res => {
          // console.log(321321321, res);
        },
        dialogArgs: {
          isview: 1,
          applyInfo: 1,
        },
        center: "center",
      });
    },
    //点击自定义
    handleCustom(item) {
      this.customItem.type = item.type;
      this.dialogVisible = true;
    },
    beforeClose(done) {
      this.clearCustomItem();
      done();
    },
    cancelDialog() {
      this.dialogVisible = false;
      this.clearCustomItem();
    },
    clearCustomItem() {
      this.customItem = { name: "", type: "" };
      this.checkboxList = [{ text: "", value: 0, check: false, base: true }];
    },
    dialogSubmit() {
      let arr = JSON.parse(JSON.stringify(this.reqParams.items));
      let flag = arr.some(i => {
        return i.name == this.customItem.name;
      });
      if (flag) {
        return dsf.layer.message("该报名选项已被添加,请修改后尝试!", false);
      }
      if (!this.customItem.name) {
        return dsf.layer.message("请输入报名选项!", false);
      }

      let text = "请输入参与人" + this.customItem.name;
      if (this.customItem.type == "uploadimg") text = "请上传参与人" + this.customItem.name;
      let obj = { required: false, name: this.customItem.name, text: text, base: false, type: this.customItem.type };
      // 判断单选多选时候必填
      if (this.customItem.type === "radiobox" || this.customItem.type === "checkbox") {
        let flag = this.checkboxList.every(i => {
          return !i.text;
        });
        if (flag) {
          return dsf.layer.message("选项内容必填", false);
        }
        obj.values = this.checkboxList;
      }
      // 添加校验
      switch (this.customItem.type) {
        case "textbox":
          obj.rule = "text1";
          break;
        case "radiobox":
          obj.rule = "radio1";
          break;
        case "checkbox":
          obj.rule = "checkbox1";
          break;
        case "uploadimg":
          obj.rule = "image1";
          break;
      }
      //查找当前元素改变rule 下标
      let hasRule = this.cacheArray.filter(item => {
        return item.type === obj.type;
      });
      if (hasRule && hasRule.length) {
        let num = hasRule[hasRule.length - 1].rule.charAt(hasRule[hasRule.length - 1].rule.length - 1);
        num++;
        obj.rule = obj.rule.substr(0, obj.rule.length - 1) + String(num);
      }
      arr.push(obj);
      // 加入缓存自定义
      this.cacheArray.push(obj);
      this.reqParams.items = arr;
      this.dialogVisible = false;
      this.clearCustomItem();
    },
    //添加checkbox
    addCheckbox() {
      this.checkboxList.push({ text: "", value: this.checkboxList.length, check: false, base: false });
    },
    //删除checkboxList
    deleteCheckbox(item, index) {
      this.checkboxList.splice(index, 1);
    },
    //提交资料
    sumbitForm(callback) {
      if (!this.url) return;
      if (this.isview) return;
      if (this.$listeners["handleSumbitForm"]) {
        this.$dispatch("handleSumbitForm", this.reqParams);
        return;
      }
      let params = JSON.parse(JSON.stringify(this.reqParams));
      if (!params.form) params.items = [];
      params.items = JSON.stringify(params.items);
      params.classId = this.id;
      params.startTime = this.time ? this.time[0] : "";
      params.endTime = this.time ? this.time[1] : "";
      params.audit = params.audit || false;
      params.form = params.form || false;
      params.allowed = this.allowed;
      delete params.time;
      if (!params.maxPeopleChecked) params.people = 0;
      if (!this.allowed) {
        params.items = JSON.stringify([]);
        params.endTime = "";
        params.startTime = "";
      } else {
        if (!params.endTime || !params.startTime) {
          return dsf.layer.message("请选择报名时间", false);
        }
      }
      if (this.rangeShow) {
        params.rangeType = this.rangeType;
      }
      console.log(params);
      // console.log(params);
      const loading = dsf.layer.loading();
      let headers = {
        "Content-Type": "application/json",
      };
      this.dsf.http
        .post(this.url, JSON.stringify(params), { headers }, $$webRoot.nc)
        .done(res => {
          if (res.success) {
            if (callback && typeof callback == "function") {
              callback();
              return;
            }
            this.dsf.layer.message(res.message || "保存成功", true);
            // this.reqParams = res.data;
            // setTimeout(() => {
            //   if (this._self.$vm.$closeWindow) {
            //     this._self.$vm.$closeWindow();
            //   } else {
            //     window.close();
            //   }
            // }, 500);
          } else {
            this.dsf.layer.message(res.message || "保存失败", false);
          }
        })
        .error(response => {
          this.dsf.layer.message(response.message || "保存失败", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    //取消提交
    cancelSumbit(evt) {
      if (this.$listeners["handleCancelSumbit"]) {
        this.$dispatch("handleCancelSumbit");
      } else {
        window.close();
        // if (this._self.$vm.$closeWindow) {
        //   this._self.$vm.$closeWindow();
        // } else {
        //   window.close();
        // }
      }
    },
    changeRadio() {
      if (this.$listeners["handleChangeRadio"]) {
        this.$dispatch("handleChangeRadio", { rangeType: this.rangeType });
      }
    },
    addFormData(val) {
      Object.assign(this.reqParams, val);
    },
    clickBackSetp() {
      if (this.$listeners["handleBackSetp"]) {
        this.$dispatch("handleBackSetp");
      } else {
        this.dsf.layer.message("未绑定handleBackSetp自定义事件", false);
      }
      //  else {
      //   if (this._self.$vm.$closeWindow) {
      //     this._self.$vm.$closeWindow();
      //   } else {
      //     window.close();
      //   }
      // }
    },
  },
});
</script>
