<template>
  <div class="dsf_nc_special">
    <!-- 专题班 -->
    <div class="Special">
      <div class="imgbox">
        <img :src="imgUrl1" @click="goztb" alt="" />
      </div>
      <div class="content">
        <div class="infos" v-for="(item, index) in ztblist" :key="index" @click="goztbdetail(item['_id'])">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 训练营 -->
    <div class="Special">
      <div class="imgbox">
        <img :src="imgUrl2" @click="goxly" alt="" />
      </div>
      <div class="content">
        <div class="infos" @click="goxlydetail(item['_id'])" v-for="(item, index) in xlylist" :key="index">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 大讲堂-->
    <div class="Special" v-if="setting_nc_other_djt">
      <div class="imgbox">
        <img :src="imgUrl3" @click="goxlyNew" alt="" />
      </div>
      <div class="content">
        <div class="infos" @click="goxlydetailNew(item['_id'])" v-for="(item, index) in xlylist" :key="index">
          <span class="left">{{ item.name }}</span>
          <span class="new" v-if="isnew(item.pxsdate)">new</span>
          <div class="ishover">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="Special" v-else>
      <div class="imgbox">
        <img :src="imgUrl3" @click="godjt" alt="" />
      </div>
      <div class="content">
        <div class="infos" v-for="(item, index) in djtlist" :key="index" @click="godjtdetail(item['nc_live_info_id'])">
          <span class="left">{{ item.title }}</span>
          <span class="new" v-if="isnew(item.time_sdate)">new</span>
          <div class="ishover">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default dsf.component({
  name: "DsfNcGzSpecial",
  ctrlCaption: "贵州专题三合一",
  mixins: [$mixins.control],
  data() {
    return {
      djtlist: [],
      ztblist: [],
      xlylist: [],
      imgUrl1: require("../../../assets/styles/img/pcimg/guizhou/b2-ztb.png"),
      imgUrl2: require("../../../assets/styles/img/pcimg/guizhou/b2-xly.png"),
      imgUrl3: require("../../../assets/styles/img/pcimg/guizhou/b2-djt.png"),
    };
  },
  created() {
    this.showXltList();
    this.showZtbList();
    this.showDjtList();
  },
  computed: {
    setting_nc_other_djt(){
      return window.$$serverConfig.setting_nc_other_djt == '1';
    }
  },
  mounted() {},
  methods: {
    // 是否登录
    islogin() {
      this.$openDialog({
        title: "",
        noTitle: true,
        width: "800px",
        height: "370px",
        class: "test",
        params: {
          path: "/pc/nc/pagelogin/dialogLogin",
        },
      });
    },
    // 是否标新
    isnew(item) {
      if(!item) return;
      const newdate = new Date();
      const date = new Date(item.replace(/-/g, "/"));
      if (item) {
        if (newdate.getTime() - date.getTime() > 604800000) {
          return false;
        } else {
          return true;
        }
      }
    },
    //跳转详情
    goztbdetail(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/page.html#/pc/nc/pagespecial/specialDetail?id=${id}`);
      } else {
        this.islogin();
      }
    },
    goxlydetail(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/ncIndex.html#/pc/nc/page/pc/teachassistant/train?id=${id}`);
      } else {
        this.islogin();
      }
    },
    goxlydetailNew(id) {
      if (dsf.getCookie("isLogin")) {
        window.open(`${location.origin}/ncIndex.html#/pc/nc/page/pc/teachassistant/classroom?id=${id}`);
      } else {
        this.islogin();
      }
    },
    godjtdetail(id) {
      if (dsf.getCookie("isLogin")) {
        // dsf.layer.message("暂未开放，敬请期待");
        // return
        window.open(`${location.origin}/page.html#/pc/nc/pagelive/liveDetail?id=${id}`);
      } else {
        this.islogin();
      }
    },
    goztb() {
      if (dsf.getCookie("isLogin")) {
        window.open(`/ncIndex.html#/pc/nc/pagespecial/specialList`);
      } else {
        this.islogin();
      }
    },
    goxly() {
      if (dsf.getCookie("isLogin")) {
        window.open(`/ncIndex.html#/pc/nc/class/train/list`);
      } else {
        this.islogin();
      }
    },
    goxlyNew() {
      if (dsf.getCookie("isLogin")) {
        window.open(`/ncIndex.html#/pc/nc/class/train/list?type=djt`);
      } else {
        this.islogin();
      }
    },
    godjt() {
      if (dsf.getCookie("isLogin")) {
        // dsf.layer.message("暂未开放，敬请期待");
        //   return
        window.open(`/ncIndex.html#/pc/nc/page/pc/training/list`);
      } else {
        this.islogin();
      }
    },
    // 专题班列表
    showZtbList() {
      let that = this;
      const mainUrl = "/nc/gz/home/ztb/list";
      const params = {
        type: 1,
        pageNum: 1,
        pageSize: 5,
        query: JSON.stringify({ searchValue: "" }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.ztblist = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 训练营列表
    showXltList() {
      let that = this;
      const mainUrl = "/nc/gz/home/ztb/list";
      const params = {
        type: 2,
        pageNum: 1,
        pageSize: 5,
        query: JSON.stringify({ searchValue: "" }),
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success) {
            that.xlylist = res.data;
          } else {
            that.$message({
              message: res.message || "获取列表数据异常",
              type: "warning",
            });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
    // 大讲堂列表
    showDjtList() {
      let that = this;
      const mainUrl = "/nc/gz/home/live/list";
      const params = {
        query: JSON.stringify({
          searchValue: "",
          ds_unit_id: '@[url("pid")]',
          real_state_value: ["2"],
        }),
        order: [],
        filter: [],
        namespace: "nc.live.info",
        pageName: "list",
      };
      that.dsf.http
        .get(mainUrl, params)
        .done(function (res) {
          if (res.success && res.data.length > 0) {
            that.djtlist = res.data;
          } else {
            // that.$message({
            //   message: res.message || "获取列表数据异常",
            //   type: "warning",
            // });
          }
        })
        .error(function (error) {
          //  that.dsf.layer.toast("服务器异常", false);
        })
        .always(function () {
          //  that.dsf.layer.closeLoading(loader);
        });
    },
  },
});
</script>

<style></style>
